import { RenderLazy } from '@lib/shared/render';
import { httpBuildQuery, bounceBack } from '@lib/shared/util';

export default function(e, currentTarget) {
  var clicked = $(currentTarget),
    post_id = clicked.attr('data-post-id'),
    target = clicked.attr('data-target'),
    rsvp_or_attendee = clicked.attr('data-type'),
    scroll = $(target).offset().top - 50;

  var data = {
    namespace: 'sg_events'
  };
  data[rsvp_or_attendee] = post_id;

  let config = {
    templatePath: 'attendee-master/atm-sg-events',
    context: 'ui-sg-events/?' + httpBuildQuery(data),
    node: $(target)
  };

  RenderLazy(config)
    .then(data => {
      bounceBack(scroll);
    })
    .finally(() => {
      $(target).removeClass('invisible');
    })
    .catch(err => {
      if (err.message == rest_obj.response_codes.REST_NO_DATA) {
        $(target).html('<div>No data</div>');
      }
    });
}

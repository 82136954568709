import { fg_alert, get_selected_party, get_party_meta, are_you_sure } from '@lib/shared/util';
import Globals from '@lib/globals';
import { ajaxPromise } from '@lib/shared/ajax';

export default function(e, ct) {
  // Initiate ajax call (get the data)
  var btn = $(ct),
    which_notification_class = btn.attr('data-class'),
    a_id = btn.attr('data-attendee-id'),
    not_first_class = btn.hasClass('_first-class-only'),
    subject = $('#_genericSubject').val(),
    p_id = get_selected_party();

  var submit_ajax = async function() {
    /* Before Send init */
    $('._qv-results').remove();
    $(`#inviteResponse-${a_id}`).removeClass(Globals.STYLES.fgAlert_danger).empty();

    try {
      const response = await ajaxPromise({
        which_notification: which_notification_class,
        actual_send: 'commit',
        subject: subject,
        p_id: p_id,
        a_id: a_id,
        batch_size: 1,
        action: 'individual_admin_email'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        $(`#inviteResponse-${a_id}`).html(response.msg).addClass(Globals.STYLES.fgAlert_danger);
        return;
      }

      /* Success status condition */
      var html = btn.html();
      btn
        .html(html + ' Sent')
        .addClass('disabled')
        .parents('._quickview-grid')
        .after('<pre class="_qv-results">' + response.msg + '</pre>');
    } catch (e) {
      /* Failure condition */
    }
  };

  // Validation

  var is_generic = btn.is('._fg-generic');

  if (is_generic) {
    if (!subject) {
      return fg_alert('Provide a subject');
    }
  } else {
    if (!p_id) {
      return fg_alert('Choose a party');
    }

    if (not_first_class) {
      return fg_alert('Cannot send for this attendee while first class is active');
    }

    var party_on_ice_or_over =
      get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.onice.int ||
      get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.partyover.int;
    if (party_on_ice_or_over) {
      return fg_alert('Must choose an active party not on ice or party-over');
    }
  }

  are_you_sure('send this message', () => submit_ajax());
}

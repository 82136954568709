import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { RenderLazy } from '@lib/shared/render';
import atmOverlays from '@template/partials/atmOverlays';

export default function(e, ct) {
  var clicked = $(ct),
    meta_value = clicked.data('rating'),
    post_id = clicked.parents('._star-rating').attr('data-attendee-id'),
    container = clicked.parents('._star-rating'),
    lazyTarget = container.attr('data-target'),
    featured_img = $(`#_featured-image-${post_id}`);

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        post_id: post_id,
        meta_key: 'wpcf-rating',
        meta_value: meta_value,
        action: 'user_edit_attendee_meta'
      });

      const success = response.status == Globals.STATUS_SUCCESS;

      /* Success status condition */
      (async c => {
        let config = {
          templatePath: 'partials/ratings-lazy',
          context: { rating: meta_value },
          node: $(lazyTarget),
          append: false,
        };

        await RenderLazy(config);
        const downOrUp = success ? 'up' : 'down';
        const color = success ? 'success' : 'danger';
        $("#thumbIcon").remove();
        $(c).append(
          `<span id="thumbIcon" style="padding-left: 1em; width: auto;" class="text-${color} animated fadeIn fas fa-thumbs-${downOrUp}"></span>`
        );
      })(container);
      var newIconOverlay = atmOverlays({ rating: meta_value }, Globals.STYLES);
      featured_img
        .find('._icon-overlay')
        .remove()
        .end()
        .append(newIconOverlay)
        .find('picture')
        .attr('data-rating', meta_value);
    } catch (e) {
      /* Failure condition */
    }
  })();
}

import { animateCSS, fg_alert, waiting, done_waiting, bounceBack } from '@lib/shared/util';
import { ajaxPromise, load_grid, load_profile_images } from '@lib/shared/ajax';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
  var make_primary_btn = $(currentTarget),
    the_parent = make_primary_btn.parents('._image-management-data'),
    a_id = the_parent.attr('data-attendee-id'),
    thumb_id = the_parent.attr('data-thumb-id'),
    allow_face = the_parent.data('allow-face'),
    face_showing = the_parent.data('face-showing'),
    image_status = the_parent.attr('data-image-status'),
    on_ice = $('._on-ice-wrapper._on-ice').length > 0,
    under_review = image_status == Globals.IMAGE_STATUS.review.int,
    user_hidden = image_status == Globals.IMAGE_STATUS.hidden.int,
    scroll = $("#_profile-images").offset().top;

  if (!allow_face && face_showing) {
    return fg_alert(
      'You cannot make this image primary because you chose not to share face pics. Select "Allow Face" above to make this primary.'
    );
  }

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        a_id: a_id,
        thumb_id: thumb_id,
        action: 'user_make_primary_image'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_error(response.data);
        return false;
      }

      /* Success status condition */
      await load_profile_images();
      bounceBack( scroll );
      if (!on_ice) {
        load_grid();
      }
    } catch (e) {
      if ( e.message != "no_pics" ) {
        /* Failure condition */
      }
    }
  })();
}

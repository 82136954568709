import Globals from '@lib/globals';
import { valid_email, log_google_analytics, fg_alert, animateCSS } from '@lib/shared/util';
import GA from '@lib/ga';
import { ajaxPromise } from '@lib/shared/ajax';

export default function(e, currentTarget, arb_args) {
  var btn = $(e.currentTarget),
    form = arb_args.parent,
    email_field = form.find('input.wpcf-email'),
    email = email_field.val(),
    email_valid = valid_email(email),
    first_name = $('input.wpcf-first-name-private').val() || '',
    sanitize_age = function(num) {
      var check_num = num.replace(/[^0-9]/g, '');
      return check_num.length > 0 && check_num < 99 && check_num > 17 ? check_num : '';
    },
    clear_fields = function() {
      $('input[type=text],input[type=email],input[type=number]').val('');
      $('textarea').val('');
      $('input[type=radio]').prop('checked', false);
      $('input[type=checkbox]').prop('checked', false);
      $('._proof-read')
        .addClass('d-none')
        .find('pre')
        .html('');
      $('.form-control.is-valid').removeClass('is-valid');
    },
    age = sanitize_age($('input.wpcf-age').val()),
    location = $('input.wpcf-location').val() || '',
    position = $('input.wpcf-position:checked').attr('data-value') || '',
    message = $('textarea._fg-body').val() || '',
    referer = $('body').attr('data-referer'),
    ajax_new_member = async token => {
      /* Before Send init */

      try {
        const response = await ajaxPromise({
          action: 'send_new_member_inquiry_email',
          email: email.trim(),
          wpcf_first_name_private: first_name.trim(),
          wpcf_age: age,
          wpcf_position: position,
          wpcf_location: location.trim(),
          message: message,
          referer: referer,
          grecaptcha_token: token
        });

        /* Error status condition */
        if (response.status === Globals.STATUS_ERROR) {
          log_google_analytics($(btn), function(element) {
            return GA.dispatch('inviteFailed', email);
          });
          btn
            .html('Send')
            .removeClass('disabled btn-success')
            .addClass('btn-danger btn-half-block');
          return fg_alert('Problem sending email: ' + response.msg);
        }

        /* Success status condition */
        log_google_analytics($(btn), function(element) {
          return GA.dispatch('inviteSubmit');
        });
        clear_fields();
        btn
          .html('Request sent')
          .removeClass('btn-danger disabled btn-half-block')
          .addClass('btn-success');
        fg_alert(response.msg, () => {
          setTimeout(function() {
            $(btn)
              .html('Send')
              .removeClass('btn-legiblue disabled')
              .addClass('btn-success btn-half-block');
          }, 3000);
        });
      } catch (e) {
        /* Failure condition */
      }
    };

  // Main

  $('#_invalidInviteRequest').removeClass('d-block');

  // Don't double send
  if (btn.hasClass('disabled')) {
    return false;
  }

  // Custom validity for e-mail
  $('input.wpcf-email')
    .get(0)
    .setCustomValidity(email_valid ? '' : 'Invalid e-mail format');

  if (!validate_input_fields(form, btn)) {
    let invalid_feedback = $('#_invalidInviteRequest');
    invalid_feedback.addClass('d-block');
    btn
      .removeClass('btn-success')
      .addClass('btn-danger')
      .html('Missing Fields');
    animateCSS(invalid_feedback, 'shakeX').then(() => {
      setTimeout(() => {
        btn
          .addClass('btn-success')
          .removeClass('btn-danger')
          .html('SEND');
      }, 3000);
    });
  } else {
    grecaptcha.ready(function() {
      // do request for recaptcha token
      // response is promise with passed token
      grecaptcha
        .execute(ajax_obj.RECAPTCHA_SITE_KEY, {
          action: 'new_member_request'
        })
        .then(function(token) {
          ajax_new_member(token);
        })
        .catch(error => {
          ajax_new_member('0'); // disables grecaptcha and lets it go through
          fg_error(error, 'Error during grecaptcha');
        });
    }); // end ready
  }
}

function validate_input_fields(form, btn) {
  // Set up form validation.. see https://getbootstrap.com/docs/4.0/components/forms/?#validation

  if (!$(form).hasClass('needs-validation')) {
    return true;
  }

  var validateFields = function(input) {
    return $(input)[0].checkValidity();
  };

  $(form)
    .find('.is-invalid')
    .removeClass('is-invalid')
    .end()
    .find('.is-valid')
    .removeClass('is-valid');

  var required_fields = $(form).find('input[required="required"]'),
    needs_validation = $(form).find('.needs-validation'),
    passed_validation = true;

  required_fields.add(needs_validation).each(function(idx, input) {
    if (validateFields(input) === false) {
      passed_validation = false;
    }
  });

  $(form)
    .find(':invalid')
    .add($(btn))
    .addClass('is-invalid');
  $(form)
    .find(':valid:required')
    .addClass('is-valid');

  let radios = $(form)
    .find('.is-invalid')
    .filter((i, node) => $(node).is('input[type="radio"]')).length;
  $(form)
    .find('.position-check-group + .invalid-feedback')
    .toggleClass('d-block', radios > 0);

  return passed_validation;
}

import Globals from '@lib/globals';

function handle_choose() {
  var clicked = $(e.currentTarget);
  var is_checked = clicked
      .find('input')
      .is(':checked'),
    a_id = clicked
      .find('input')
      .data('attendee-id'),
    chosen_attendees = get_chosen(),
    count = update_chosen_count();

  $('._submit-chosen-for-party').toggleClass('disabled', count < 1);
  clicked
    .parents('._grid__brick')
    .toggleClass(Globals.STYLES.gridBrick__chosen, is_checked);

  if (is_checked) {
    if (!chosen_attendees.includes(a_id)) {
      chosen_attendees.push(a_id);
    }
  } else {
    if (chosen_attendees.includes(a_id)) {
      const index = chosen_attendees.indexOf(a_id);
      if (index > -1) {
        chosen_attendees.splice(index, 1);
      }
    }
  }
  $('#_chosenAttendees').attr('data-attendees', chosen_attendees.join(','));
}


import { ajaxPromise } from '@lib/shared/ajax';
import { log_google_analytics } from '@lib/shared/util';
import GA from '@lib/ga';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
  var btn = $(currentTarget),
    sms = $('._fg-sms')
      .val()
      .trim(),
    a_id = $('._fg-sms').attr('data-attendee-id'),
    contact_id = $('._fg-sms').attr('data-contact-id'),
    allow_sms = btn.is('._fg-save-sms'),
    dismiss = btn.is('._fg-sms-dismiss'),
    stop_asking = btn.is('._fg-sms-stop-asking'),
    the_alert = $('#_fg-text-opt-in ._fg-alert'),
    presentError = resp => {
      var err = resp.msg
        ? resp.msg
        : 'We had a small problem saving this. Contact host to supply your number. ';
      the_alert
        .html(err)
        .removeClass('d-none')
        .addClass('animated shakeX');
      return false;
    };

  if (dismiss) {
    log_google_analytics(this, function(element) {
      return GA.dispatch('smsOptIn', 'Disallow', 'No thanks');
    });
    return;
  }

  (async () => {
    /* Before Send init */
    the_alert.addClass('d-none').removeClass('animated shakeX');

    try {
      const response = await ajaxPromise({
        sms: sms,
        post_id: a_id,
        contact_id: contact_id,
        allow_sms: allow_sms,
        stop_asking_sms: stop_asking,
        action: 'add_update_sms'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        return presentError(response);
      }

      /* Success status condition */
      log_google_analytics(this, function(element) {
        const action = allow_sms ? 'Allow' : 'Disallow';
        const label = allow_sms ? sms : stop_asking ? 'Stop asking' : 'No thanks';
        return GA.dispatch('smsOptIn', action, label);
      });
      btn.html('Saved!');
      setTimeout(function() {
        $('#_fg-text-opt-in').modal('hide');
      }, 1000);
    } catch (e) {
      /* Failure condition */
      presentError(e);
    }
  })();
}

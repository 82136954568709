
/** This might be ultimately deprecated **/

export function handle_your_own_party(e) {
    var btn = $(e.currentTarget),
        a_id = btn.attr('data-attendee-id'),
        all_inputs = $('._yop'),
        request_data = { action: 'request_your_own_party', a_id: a_id },
        alert_response = $('#_alertYourOwnParty'),
        validated = false,
        missing = [],
        do_ajax = function () { ajax_entry_point(
          request_data,
          function(xhr) {
            // beforeSend
            alert_response.removeClass('danger success').addClass('d-none');
            all_inputs.removeClass('is-invalid');
          },
          function(response, status, data) {
            // done
            if (Globals.STATUS_SUCCESS == status) {
              alert_response.removeClass('d-none').addClass('success').html(response.msg);
              $('#_collapseYourOwnParty').collapse('hide');
              all_inputs.val('');
              setTimeout( function() {
                util.animateCSS( alert_response, 'fadeOut').then((message) => {
                   alert_response.addClass('d-none');
                });
              }, 5000);
            } else {
              alert_response.removeClass('d-none').addClass('danger').html(response.msg);
            }
          },
          function(response, textstatus, xhr) {
            // always
          },
          function(jqXHR, textStatus) {
            // fail
            btn.removeClass('d-none').addClass('danger').html(textStatus);
          }
        ) },
        validate = function () {
          all_inputs.removeClass('is-invalid').each(function( idx, input ) {
              var name = $(input).attr('name'),
                  value = $(input).val(),
                  required = $(input).data('required');

              if ( required && !value ) {
                missing.push( $(input).attr('data-label') );
                $(input).addClass('is-invalid');
              }
              if ( $(input).is('input[type="checkbox"]') ) {
                  request_data[name] = $(input).is(':checked');
                  return;
              }
              request_data[name] = value;
          });
        };

      validate();
      if ( missing.length > 0 ) return util.fg_alert('Required: ' + missing.join(', ') ) ;
      do_ajax();

}

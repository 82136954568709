import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
export default function(e, ct) {
  var btn = $(ct);
  // This will later be checked by isAjaxLocked(), must remove in finally
  $('#fg-delegate').attr('data-override-target', '._fg-set-brakes');

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'emergency_brakes'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_error(response);
        return fg_alert('Problem setting brakes');
      }

      /* Success status condition */
      btn
        .html('Brakes Set')
        .toggleClass('btn-success', Globals.STATUS_SUCCESS == status)
        .toggleClass('btn-danger', Globals.STATUS_SUCCESS != status);
      setTimeout(() => {
        btn
          .html('Set Brakes')
          .addClass('btn-danger')
          .removeClass('btn-success');
      }, 3000);
    } catch (e) {
      /* Failure condition */
    } finally {
      $('#fg-delegate').attr('data-override-target', '');
    }
  })();
}

import { fg_alert, waiting, done_waiting, get_pic_count, animateCSS } from '@lib/shared/util';
import { ajaxPromise, load_grid, load_profile_images } from '@lib/shared/ajax';
import { user_endpoint_init } from '@lib/shared/state';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
  var btn_hide = $(currentTarget),
    data_elem = btn_hide.parents('._image-management-data'),
    image_id = data_elem.attr('data-other-images-id'),
    a_id = data_elem.attr('data-attendee-id'),
    current_image_status = data_elem.attr('data-image-status'),
    to_overlay = btn_hide.parents('._image-management-data').find('._fg-single-image-public'),
    is_primary = to_overlay.hasClass('primary'),
    on_ice = $('._on-ice-wrapper._on-ice').length > 0,
    pic_count = get_pic_count();

  if (is_primary && current_image_status != Globals.IMAGE_STATUS.hidden.int) {
    return fg_alert(
      'You cannot hide your primary image. Change your primary image first. Or, if this is your only visible pic, you must delete it to continue.'
    );
  }

  if ( pic_count == 1 && current_image_status != Globals.IMAGE_STATUS.hidden.int ) {
    return fg_alert(
      'You cannot hide your only visible image. If this is your only visible pic, you must delete it to continue.',
    );
  }

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        image_id: image_id,
        a_id: a_id,
        image_status:
          current_image_status == Globals.IMAGE_STATUS.hidden.int
            ? Globals.IMAGE_STATUS.approved.int
            : Globals.IMAGE_STATUS.hidden.int,
        action: 'user_make_image_hidden'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_error(response.data);
        animateCSS($(btn_hide).html(response.msg), 'bounce');
        return false;
      }

      /* Success status condition */
      await load_profile_images();
      if (!on_ice) {
        load_grid().then(() => {
          user_endpoint_init();
        });
      }
    } catch (e) {
      if ( e.message != "no_pics" ) {
        /* Failure condition */
      }
    }
  })();
}

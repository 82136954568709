export default function (e, currentTarget) {
  var btn = $(currentTarget),
    input = $('[class*="_fg-input-change-password"]'),
    type = input.attr('type');
  input.attr('type', type == 'password' ? 'text' : 'password');
  btn.html(type == 'password' ? 'Hide Password' : 'Show Password');

  // Must return false to prevent LastPass from submitting
  return false;
}

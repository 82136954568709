import { waiting, done_waiting } from '@lib/shared/util';
import Globals from '@lib/globals';
import { ajaxPromise, load_grid } from '@lib/shared/ajax';
import { update_tag_counts } from '@lib/shared/filtering';

export default function(e, currentTarget) {
  var btn_tag = $(currentTarget),
    save_html = btn_tag.html();

  // Can't click if blurred or if clicking an already open input
  if (btn_tag.parents('._fg-editable').hasClass(Globals.STYLES.fadeAndBlur)) {
    return false;
  }

  var tag_slug = btn_tag.attr('data-tag'),
    a_id = btn_tag.parents('._fg-edit-post').attr('data-post-id'),
    add_tag = !btn_tag.hasClass('term-attached');

  (async () => {
    /* Before Send init */
    waiting(btn_tag);

    try {
      const response = await ajaxPromise({
        a_id: a_id,
        slug: tag_slug,
        add_tag: add_tag ? '1' : '0',
        action: 'tag_attendee'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        done_waiting(btn_tag);
        btn_tag.removeClass('btn-default outline fg-btn-selected term-attached').addClass('btn-danger');
        return false;
      }

      /* Success status condition */
      done_waiting(btn_tag);
      btn_tag.toggleClass('fg-btn-selected term-attached', add_tag).toggleClass('btn-default', !add_tag);
      await load_grid();
      update_tag_counts();
    } catch (e) {
      if (e.message != 'no_pics') {
        /* Failure condition */
        btn_tag
          .html(save_html)
          .attr('style', '')
          .removeClass('btn-default outline fg-btn-selected term-attached')
          .addClass('btn-danger');
      }
    }
  })();
}

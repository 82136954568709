import Globals from '@lib/globals';

export function handle_filter_complete() {
  var chosen_attendees = get_chosen();

  $('._grid__brick').each(function(idx, _grid__brick) {
    var checkbox = $(_grid__brick).find('input.form-check-input'),
      this_a_id = checkbox.data('attendee-id'),
      checked = chosen_attendees.includes(this_a_id);

    checkbox.prop('checked', checked);
    $(_grid__brick).toggleClass(Globals.STYLES.gridBrick__chosen, checked);
  });

  update_chosen_count();

}

function update_chosen_count() {
  var count = $('._check-choose input').filter(function() {
     return $(this).is(':checked');
   }).length;
   $('._chosen-count span').html(count);
   return count;
}


import {
  create_search_construct,
  add_filter_breadcrumb,
  searchAndRender,
  searchbox_filter,
  update_elements_after_filter
} from '@lib/shared/filtering';
import Globals from '@lib/globals';

export default function(e, ct) {
  if (e.key === 'Enter' || e.keyCode == 13) {
    return false;
  }

  const searchingFor = $(ct)
      .val()
      .toLowerCase()
      .trim(),
    reset = !searchingFor || searchingFor == '',
    searchConstruct = create_search_construct(searchingFor);

  if (searchingFor != '') {
    add_filter_breadcrumb('Search: ' + searchingFor, Globals.FILTER_SEARCH, searchingFor);
  }

  const args = {
    reset: reset,
    matchFunction: searchbox_filter,
    searchConstruct: searchConstruct
  };
  searchAndRender(args)
    .then(locked_candidates => update_elements_after_filter())
    .catch(err => fg_error(err));
}

import Globals from '@lib/globals';
import { ajaxPromise, reload_parties_attended } from '@lib/shared/ajax';

export default function(e, ct) {
  var clicked = $(ct),
    parent = clicked.parents('._quickview-grid'),
    r_id = parent.attr('data-rsvp-id'),
    current_showed_value = clicked.data('showed');

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'mark_showed',
        showed: !current_showed_value,
        r_id: r_id
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        clicked
          .html(response.msg)
          .removeClass('btn-success')
          .addClass('btn-danger');
        return false;
      }

      /* Success status condition */
      clicked
        .html(response.msg)
        .toggleClass('btn-success', !current_showed_value)
        .toggleClass('btn-danger', current_showed_value)
        .data('showed', response.data.showed == 1 ? true : false);

      reload_parties_attended();

    } catch (e) {
      /* Failure condition */
    }
  })();
}

import { animateCSS, log_google_analytics, valid_email } from '@lib/shared/util';
import Globals from '@lib/globals';
import { ajaxPromise } from '@lib/shared/ajax';
import GA from '@lib/ga';

export default function(e, currentTarget) {
  var btn_clicked = $(currentTarget),
    email_input = $('._fg-email-request-access'),
    fragment = $('.fg-access').attr('data-fragment'),
    email = $(email_input)
      .val()
      .trim(),
    email_is_valid = valid_email(email),
    the_alert = $('._fg-access ._fg-alert'),

    ajax_request_reset_data = async function(token) {

      /* Before Send init */
      btn_clicked.addClass('disabled');
      the_alert.remove();

      try {
        const response = await ajaxPromise({
          email: email,
          fragment: fragment,
          action: 'reset_and_email_rsvp_link',
          grecaptcha_token: token,
        });

        /* Log analytics either way */
        log_google_analytics(this, function(element) {
          const op_status = Globals.STATUS_SUCCESS == status ? 'success' : response.msg;
          const user = response.data.attendee_id || response.data.email || 'unknown';
          return GA.dispatch('userAccess', user, 'Reset Link', op_status);
        });

        /* Error status condition */
        if (response.status === Globals.STATUS_ERROR) {
          $('._fg-access').append(`<label class="animated shakeX _fg-alert ${Globals.STYLES.fgAlert_danger}">${response.msg}</label>`);
          btn_clicked.removeClass('disabled');
          return false;
        }

        /* Success status condition */
        btn_clicked.add(email_input).addClass('d-none');
        $('._fg-access').append(
          `<label class="_fg-alert ${Globals.STYLES.fgAlert_success} animated fadeInUp"><span>${response.msg}</span></label>`
        );

        setTimeout( () => {
          $("#returnToLogin").trigger("click");
        }, 20000);

      } catch (e) {
        /* Failure condition */
        let message = e.message || e;
        $('._fg-access').append(`<label class="_fg-alert ${Globals.STYLES.fgAlert_danger}">${message}</label>`);
        animateCSS(the_alert.html(message), 'shakeX');
        btn_clicked.removeClass('disabled');
      }
    }; // end anonymous function ajax_request_reset_data()

  if (!email_is_valid) {
    $('._fg-access')
      .find('._fg-alert')
      .remove()
      .end()
      .append(`<label class="_fg-alert ${Globals.STYLES.fgAlert_danger}"></label>`);
    animateCSS($('._fg-alert').html('Invalid email format'), 'shakeX');
    return false;
  }

  grecaptcha.ready(function() {
    // do request for recaptcha token
    // response is promise with passed token
    grecaptcha
      .execute(ajax_obj.RECAPTCHA_SITE_KEY, {
        action: 'handle_party_access'
      })
      .then(function(token) {
        ajax_request_reset_data(token);
      })
      .catch(error => {
        ajax_request_reset_data(0); // disables grecaptcha and lets it go through
        fg_error(error, 'Error during grecaptcha');
      });
  }); // end ready

  // Prevents LastPass from triggering
  return false;
}

import Globals from '@lib/globals';
import { RenderLazy } from '@lib/shared/render';
import { httpBuildQuery, get_selected_party, animateCSS } from '@lib/shared/util';
import { reload_rsvp_counts, reload_parties_attended } from '@lib/shared/ajax';
import loadModule from '@lib/shared/loadModule';

export default function(e, ct) {
  var clicked = $(e.target) || $(ct),
    target = clicked.attr('data-target'),
    template = '',
    endpoint = '',
    beforeSend = false,
    callback = false;

  // for active tabs, add a click handler for refresh purposes
  // Also remove content between clicks
  $('.nav-tabs a')
    .removeClass('handle-click')
    .attr('data-module', '')
    .map((i, e) => $(e).attr('data-target'))
    .get()
    .map(t => $(t).empty());

  clicked.addClass('handle-click').attr('data-module', 'handle_launch_tab_control');

  $('.tab-content').addClass(Globals.STYLES.progress);

  $('._load-more').remove();

  switch (target) {
    case '#_launch-sg-log':
      template = 'sglog';
      endpoint = ['ui-sg-event-log/'];
      break;
    case '#_launch-welcome-email':
      template = 'welcome-email';
      endpoint = {};
      callback = () => {
        var cleaned = $('#_launch-welcome-email ._fg-body')
          .get(0)
          .getInnerHTML()
          .replace(/[ ]{2,}/g, '');
        $('._fg-body').html(cleaned);
      };
      break;
    case '#_launch-rsvp-console':
      template = 'rsvp-console';
      beforeSend = () => {
        return Promise.all([reload_rsvp_counts, reload_parties_attended].map(async func => await func()));
      };
      endpoint = [
        'ui-rsvp-console/?' +
          httpBuildQuery({
            p_id: get_selected_party()
          }),
        'ui-misc/?' +
          httpBuildQuery({
            namespace: 'unsent_notification_counts',
            p_id: get_selected_party()
          }),
        { response: { picture_data: Globals.FEATURED_IMAGES } }
      ];
      break;
    case '#_launch-analytics':
      template = 'analytics';
      endpoint = [
        'analytics/?' +
          httpBuildQuery({
            p_id: get_selected_party(),
            is_featured_image: true,
            namespace: 'analytics'
          }),
        'ui-active-tokens',
        { response: { picture_data: Globals.FEATURED_IMAGES } }
      ];
      break;
    case '#_launch-approve-images':
      template = 'approve-images';
      endpoint = [
        'ui-picture-engine/?' +
          httpBuildQuery({
            namespace: 'image_management',
            image_size: 'small-full',
            filter_image_status: Globals.IMAGE_STATUS.review.int
          })
      ];
      break;
    case '#_launch-purge':
      template = 'purge';
      break;
    case '#_launch-init-rsvp':
      template = 'init-rsvp';
      break;
    case '#_launch-bulk-email':
      template = 'bulk-email';
      endpoint = [
        'ui-misc/?' +
          httpBuildQuery({
            namespace: 'unsent_notification_counts'
          })
      ];
      callback = () => {
        $('#fg-delegate').on('click', '#queryRecipients', e => {
          var checkbox = $(e.currentTarget),
            isChecked = checkbox.is(':checked');
          $('button[data-module="handle_launch_bulk_emails"]')
            .toggleClass(['_bulk-query-only', 'btn-outline-success'], isChecked)
            .toggleClass('btn-success', !isChecked);
        });
      };
      break;

    default:
  }

  (async (preRender, cb) => {
    let config = {
      templatePath: 'launch/launch-panel-' + template,
      context: endpoint,
      node: $(target)
    };

    if (preRender && preRender instanceof Function) {
      await preRender();
    }

    const context = await RenderLazy(config);

    // affects all tabs
    $('.tab-content').removeClass(Globals.STYLES.progress);

    // optionally declare a callback above
    if (cb && cb instanceof Function) {
      cb();
    }

    // Set up copy click worth doing for all
    (async function() {
      var ClipboardJS = await loadModule('clipboard');
      var clipboard = new ClipboardJS.default('._fg-copy-click');
      clipboard.on('success', function(event) {
        var clicked = $(event.trigger);
        animateCSS(clicked, 'fadeOutUp faster').then(message => {
          event.clearSelection();
        });
      });
    })();
  })(beforeSend, callback);
}

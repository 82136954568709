import { get_selected_party, fg_alert, get_parties_attended, get_rsvp_icon } from '@lib/shared/util';
import { add_filter_breadcrumb, searchAndRender, update_elements_after_filter } from '@lib/shared/filtering';
import Globals from '@lib/globals';

export default function(e, ct) {
  var btn = $(ct),
    p_id = get_selected_party(),
    data_showed = btn.data('showed') ? true : false,
    all = data_showed == 'all';

  if (!p_id) {
    return fg_alert('Select a Party');
  }

  add_filter_breadcrumb(data_showed ? 'Showed' : 'No Show', Globals.FILTER_SHOWED, data_showed);

  const args = {
    matchFunction: function(element) {
      var a_id = $(element)
          .find('._fg-modal-on-demand')
          .attr('data-attendee-id'),
        party_meta = get_parties_attended(a_id, p_id);

      if (party_meta) {
        $(element)
          .find('._fg-thumb')
          .after(get_rsvp_icon(party_meta['reply_n'], a_id));
      }

      return all || (party_meta && party_meta['showed'] === data_showed);
    }
  };

  searchAndRender(args)
    .then(locked_candidates => update_elements_after_filter())
    .catch(err => fg_error(err));
}

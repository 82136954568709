import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { animateCSS, are_you_sure } from '@lib/shared/util';
import loadModule from '@lib/shared/loadModule';

export default function(e, ct) {
  var clicked = $(ct),
    a_id = clicked.attr('data-attendee-id'),
    passwords = [
      'boston',
      'uncut',
      'jockstrap',
      'poppers',
      'takingdick',
      'horny',
      'breeding',
      'cumdump',
      'shooter',
      'dickbreath',
      'sloppy',
      'harness'
    ],
    // Returns a random integer from 0 to 9:
    randomIndex = Math.floor(Math.random() * passwords.length),
    password = passwords[randomIndex] + '123';

  const ajax_submit = async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'password_store_new',
        a_id: a_id,
        password: password,
        grecaptcha_token: 0, // bypasses grecaptcha
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_error(response);
        return fg_alert('Problem changing password');
      }

      /* Success status condition */
      clicked
        .html(response.msg)
        .after(
          `<div data-clipboard-text="${password}" class="small _password-copy-click py-3 text-center">${password}<i class="far fa-copy ml-2 text-muted"></i></div>`
        );

      (async function() {
        var ClipboardJS = await loadModule('clipboard');

        var clipboard = new ClipboardJS.default('._password-copy-click');
        clipboard.on('success', function(event) {
          var clicked = $(event.trigger);
          animateCSS(clicked, 'fadeOutUp faster').then(message => {
            event.clearSelection();
          });
        });
      })();
    } catch (e) {
      /* Failure condition */
    }
  };

  are_you_sure( "reset this user's password?", ajax_submit );
}

import { log_google_analytics } from '@lib/shared/util';
import GA from '@lib/ga';

const typingHistory = {};

export default function(e, currentTarget, arb_args) {
  let parent = arb_args.parent;
  var input = currentTarget,
    event_type = e.type,
    field = input.attr('name').replace(/wpcf-/,''),
    value = input.is('[type="radio"]') ? input.attr('data-value') : input.val();
  if (!value || value.trim() == '') {
    return;
  }
  if ((event_type == 'keyup' || event_type == 'focusout') && field.indexOf('email') > -1) {
    let proofread = parent.find('._proof-read');
    proofread
      .removeClass('d-none')
      .find('pre')
      .html(value);
  }
  avoidDuplication(field, value) &&
    log_google_analytics(input, function(element) {
      return GA.dispatch('inviteTyping', field, value);
    });
}

function avoidDuplication(f, v) {
  let field_history = typingHistory[f] || [],
    found = field_history.length && field_history.includes(v);
  if (found) return false;
  field_history.push(v);
  typingHistory[f] = field_history;
  return true;
}

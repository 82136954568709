import {
  ajaxPromise,
  load_profile_images,
  reload_rsvp_counts,
  load_grid,
  reload_attendee_essentials
} from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { determine_rsvp_selection_state } from '@lib/shared/state';
import { fg_alert, progressIcon, is_waitlist_in_effect, are_you_sure, admin_logged_in } from '@lib/shared/util';
import { update_tag_counts, update_position_counts } from '@lib/shared/filtering';

export default function(e, currentTarget, arb_args) {
  var checked_radio = $(currentTarget);

  var parent = arb_args.parent,
    post_id = checked_radio.parents('._fg-edit-post').attr('data-post-id'),
    meta_key = checked_radio.attr('name'),
    meta_value = checked_radio.val(),
    action = checked_radio.parents('.form-group').attr('data-action'),
    the_label = checked_radio.parent().next('label'),
    previous_selection = checked_radio.parents('._fg-editable').find('input[class*="selected"]');

  // Prevent double submissions
  if (checked_radio.hasClass('selected') || checked_radio.is(':selected')) {
    e.preventDefault();
    return false;
  }

  if ($('#fg-delegate').is('[class*="ajax-"]')) {
    return false;
  }

  if (meta_key == 'wpcf-allow-face') {
    // Saying no to show face, but their primary pic shows their face.
    // Alert to change that.
    if (meta_value == '0') {
      var face_showing = $('._primary-thumb')
        .parents('._image-management-data')
        .data('face-showing');
      if (face_showing) {
        e.preventDefault();
        return fg_alert(
          'Your face is visible in your primary pic. Select a different pic to be primary before changing this otherwise your face will be visible.'
        );
      }
    }
  }

  if (meta_key == 'wpcf-allow-sms' && meta_value == '1') {
    // Saying yes to allow sms without having an SMS shouldn't be
    // allowed
    var sms_value = $('._fg-edit-post ._fg-user-sms ._fg-inline-value-placeholder').html();
    if (isNaN(sms_value)) {
      fg_alert('Please set your SMS first.');
      $('#_input-disallow-sms')[0].checked = true;
      $('#_input-allow-sms')[0].checked = false;
      return false;
    }
  }

  const submit_ajax = async () => {
    /* Before Send init */
    the_label.append(progressIcon(Globals.STYLES.progress));
    if (meta_key == 'wpcf-allow-face') {
      $('#_profile-images').empty();
    }

    try {
      const response = await ajaxPromise({
        post_id: post_id,
        meta_key: meta_key,
        meta_value: meta_value,
        action: action
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        the_label.append(progressIcon('error'));
        return false;
      }

      /* Success status condition */
      parent
        .find('input')
        .get()
        .forEach((check, i) => {
          check.checked = false;
          $(check)
            .removeClass('selected')
            .attr('checked', false);
        });
      checked_radio
        .addClass('selected')
        .attr('checked', 'checked')
        .get(0).checked = true;

      if (meta_key == 'wpcf-rsvp-reply') {
        determine_rsvp_selection_state(meta_value, post_id);
      }

      the_label.append(progressIcon('success'));

      if (!admin_logged_in()) {
        $(`#_rsvpData-${post_id}`).attr('data-current-reply', meta_value);

        if (meta_key == 'wpcf-allow-face') {
          // Must go before load_grid so that count is accurate
          await load_profile_images();
        }

        await load_grid();

        reload_attendee_essentials();

        if (meta_key == 'wpcf-rsvp-reply' || meta_key == 'wpcf-rsvp-count') {
          reload_rsvp_counts();
          update_tag_counts();
          update_position_counts();
        }
      }
    } catch (e) {
      if (e.message != 'no_pics') {
        /* Failure condition */
      }
    }
  };

  if (
    meta_key == 'wpcf-rsvp-reply' &&
    meta_value == Globals.REPLY.no.int &&
    is_waitlist_in_effect() &&
    previous_selection.length &&
    (previous_selection.val() == Globals.REPLY.fresh_yes.int || previous_selection.val() == Globals.REPLY.maybe.int)
  ) {
    e.preventDefault();
    are_you_sure(
      'change your reply while the waitlist is in effect? You will forfeit your spot.',
      () => {
        checked_radio.prop('checked', true);
        submit_ajax();
        determine_rsvp_selection_state(Globals.REPLY.no.int, post_id);
      },
      () => {
        previous_selection.prop('checked', true);
      }
    );
  } else {
    submit_ajax();
  }
}

import Globals from '@lib/globals';
import { waiting, done_waiting, animateCSS } from '@lib/shared/util';
import { ajaxPromise } from '@lib/shared/ajax';

export default function(e, ct) {
  var approve_button = $(ct),
    data_elem = approve_button.parents('._image-management-data'),
    image_id = data_elem.attr('data-other-images-id'),
    image_status = data_elem.attr('data-image-status'),
    overlay = data_elem.find('._icon-overlay'),
    tip = data_elem.find('._thumb-tip'),
    a_id = data_elem.attr('data-attendee-id');

  (async () => {
    /* Before Send init */
    waiting(approve_button);

    try {
      const response = await ajaxPromise({
        image_id: image_id,
        a_id: a_id,
        action: 'approve_user_image',
        original_image_status: image_status == '0' ? '2' : '0'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_console(response.data);
        animateCSS(
          $(approve_button)
            .removeClass('btn-success')
            .addClass('btn-danger')
            .html(response.msg),
          'bounce'
        );
        return false;
      }

      /* Success status condition */
      done_waiting(approve_button, response.msg);
      $(approve_button)
        .toggleClass('btn-warning', image_status == '0')
        .toggleClass('fg-approved btn-success', image_status != '0');
      data_elem
        .attr('data-image-status', image_status == '0' ? '2' : '0');
      overlay.add(tip).toggleClass('d-none', image_status == '2');
    } catch (e) {
      /* Failure condition */
    }
  })();
}

import { reset_filter_rsvp, searchAndRender, update_elements_after_filter } from '@lib/shared/filtering';
import Globals from '@lib/globals';
import { bounceBack } from '@lib/shared/util';

export default function(e, ct, arbitrary_args) {
  $('._fg-grid-wrapper').empty();
  reset_filter_rsvp(ct).then(() => {
    searchAndRender({ reset: true })
      .then(locked_candidates => update_elements_after_filter())
      .then(() => bounceBack(0))
      .then(() => {
        $('.collapse').collapse('hide');
        $('._fg-filter input[type="radio"]').prop('checked', false);
        $('._printParty > div:nth-child(2)').addClass('d-none');
        $('#_fg-search-box').val('');
        $('#filterBreadcrumb')
          .empty()
          .addClass("d-none");
        if (arbitrary_args && arbitrary_args.manual) {
          $('#_fg-search-box')
            .add('._atm-buttons .btn,._atm-buttons input,._fg-filter .btn')
            .removeClass('disabled');
        }
        let filterLock = $("#_filterLock");
        if ( filterLock.length && filterLock.attr("data-lock") == "true" ) {
          filterLock.trigger("click", {manual: true});
        }
        Globals.LOCKED_FILTERS = [];
      });
  });
}

export function handle_filter_button_effects(e) {
  var clicked = $(e.currentTarget),
      parent = clicked.parents('._fg-public-menu').length > 0 ? '._fg-public-menu' : '._fg-filter';
  if ($(e.currentTarget).hasClass('disabled')) {
    return false;
  }
  $(e.currentTarget)
    .parents(parent)
    .find('.btn')
    .removeClass('fg-btn-selected')
    .end()
    .end()
    .addClass('fg-btn-selected');
}


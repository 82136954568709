import handle_change_rsvp_party_dropdown_filter from '@lib/handlers/handle_change_rsvp_party_dropdown_filter';
import handle_unsent_notification_counts from '@lib/handlers/handle_unsent_notification_counts';
import handle_load_rsvp_console from '@lib/handlers/handle_load_rsvp_console';
import handle_launch_tab_control from '@lib/handlers/handle_launch_tab_control';
import { set_selected_party } from '@lib/shared/util';

export default function handle_select_party_dropdown(e, ct) {
  var dropdown = $(ct),
    p_id = dropdown.find('option:selected').val(),
    a_id_arr = [];

  $('#_selectedParty').attr('data-selected-party', p_id);

  // Special processing post-change
  if (dropdown.is('#_partySelectDropdown')) {
    set_selected_party( p_id );
    handle_change_rsvp_party_dropdown_filter(e, ct);
  } else if (dropdown.is('#_partySelectDropdownBulkEmail')) {
    set_selected_party( p_id );
    handle_unsent_notification_counts(e, ct);
  } else if (dropdown.is('#_partySelectDropdownRSVPConsole')) {
    set_selected_party( p_id );
    handle_load_rsvp_console(e, ct);
  } else if (dropdown.is('#_partySelectInitRSVP')) {
    set_selected_party( p_id );
  } else if (dropdown.is('#_partySelectDropdownAnalytics')) {
    set_selected_party( p_id );
    $(ct).attr('data-target', '#_launch-analytics');
    handle_launch_tab_control( e, ct );
  } else if (dropdown.is('#_partySelectDropdownAddToParty')) {
    var a_id = $('#addLink').attr('data-attendee-id'),
      p_id = dropdown.find(':selected').val(),
      linked = ajax_obj.parties_attended.filter(rec => rec.a_id == a_id && rec.p_id == p_id).length > 0;

    $('#addLink,#removeLink').addClass('d-none');
    if (p_id > 0) {
      $('#addLink').toggleClass('d-none', linked);
      $('#removeLink').toggleClass('d-none', !linked);
    }
  }
}

export function handle_show_chosen() {
  var btn = $(e.currentTarget),
    showing_only_chosen = btn.hasClass('only-chosen'),
    all = Globals.ORIGINAL_GRID,
    chosen_attendees = get_chosen(),
    chosen = all.filter(function(gb) {
      var a_id = $(gb)
        .find('input.form-check-input')
        .data('attendee-id');
      return chosen_attendees.includes(a_id);
    }),
    buffer = [];
  if (showing_only_chosen) {
    // show all
    $(all).each(function(idx, gb) {
      buffer.push($(gb).prop('outerHTML'));
    });
    btn.removeClass('only-chosen').html('View Only Chosen');
  } else {
    $(chosen).each(function(idx, gb) {
      buffer.push($(gb).prop('outerHTML'));
    });
    btn.addClass('only-chosen').html('Show All');
  }
  $('._fg-grid-wrapper').html(buffer.join(''));
}
EOF

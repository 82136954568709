import Globals from '@lib/globals';
import { RenderLazy } from '@lib/shared/render';
import { httpBuildQuery, get_selected_party, bounceBack } from '@lib/shared/util';
import { grid_item_progress } from '@lib/shared/state';

export default function(e, ct) {
  var clicked = $(ct),
    analytics_grid = $('#_analytics-grid'),
    trigger_expand_clicked = clicked.parents('._trigger-expand'),
    receive_expand_clicked = clicked.attr('data-target'),
    a_id = clicked.attr('data-attendee-id');

  const s = Globals.STYLES,
    show = s.show,
    awake = s.awake,
    collapsed = s.collapsed,
    blur = s.blur,
    isAwake = analytics_grid.find('.' + show).length > 0;

  // if clicking an expanded, collapse and return
  if (isAwake) {
    let last_scroll = analytics_grid.attr('data-scroll')
    $('._trigger-expand.' + show).addClass(collapsed);
    $('.' + show).removeClass(show);
    analytics_grid.removeClass(awake);
    bounceBack(last_scroll, 200);
    return true;
  }

  let clicked_scroll = $(window).scrollTop();
  analytics_grid.attr('data-scroll', clicked_scroll);
  grid_item_progress(clicked.find('picture'));
  clicked.find('img').addClass(blur);

  (async () => {
    let config = {
      templatePath: 'partials/analytics-history',
      context: [
        'analytics/?' +
          httpBuildQuery({
            a_id: a_id,
            p_id: get_selected_party(),
            namespace: 'analytics'
          }),
        {
          response: {
            picture_data: Globals.FEATURED_IMAGES
          }
        },
        {
          response: {
            a_id: a_id
          }
        }
      ],
      node: $(receive_expand_clicked)
    };

    const context = await RenderLazy(config);

    // Show the one we clicked on
    analytics_grid.addClass(awake);
    trigger_expand_clicked.removeClass(collapsed).addClass(show);
    $(receive_expand_clicked).addClass(show);
    grid_item_progress(clicked.find('picture'));
    clicked.find('img').removeClass(blur);
  })();
}

export function handle_user_rsvp_selection_behavior(e) {
  var reply = $(e.currentTarget).val(),
      previous_radio = $(e.currentTarget).parents('._fg-editable').find('input[class*="selected"]'),
      previous_reply = previous_radio.length ? previous_radio.val() : false;
  if (
    util.is_waitlist_in_effect() &&
    reply == Globals.REPLY.no.int &&
    (previous_reply == Globals.REPLY.fresh_yes.int || previous_reply == Globals.REPLY.maybe.int)
  ) {
    e.stopImmediatePropagation();
    return false;
  }
  determine_rsvp_selection_state(reply);
}


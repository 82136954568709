import Globals from '@lib/globals';
import { ajaxPromise, reload_rsvp_counts, load_grid } from '@lib/shared/ajax';
import {
  sanitize_sms,
  progressIcon,
  fade_other_inputs,
  handle_user_input_groups,
  is_waitlist_in_effect,
  fg_alert
} from '@lib/shared/util';

export default function handle_inline_submit(e, currentTarget) {
  const NOT_SET = 'Not set';
  var submit = $(currentTarget),
    cancel = submit.siblings('._fg-edit-inline-btn-cancel'),
    pencil = submit.siblings('._fg-edit-inline-btn-pencil'),
    action = submit.parents('._fg-edit-inline').attr('data-action'),
    meta_key = submit.parents('._fg-edit-inline').attr('data-meta-key'),
    contact_id = submit.parents('._fg-edit-inline').attr('data-contact-id'),
    post_id = submit.parents('._fg-edit-post').attr('data-post-id'),
    inline_placeholder = submit.siblings('._fg-inline-value-placeholder'),
    original_text = inline_placeholder.attr('data-original-text'),
    meta_value = submit.siblings('._fg-edit-inline-input').val(),
    label = submit.parents('._fg-editable').find('label'),
    the_alert = $(`<span id="_fg-alert-id" class="_fg-alert ${Globals.STYLES.fgAlert_danger} py-2"></span>`),
    allow_sms = $("input[name='wpcf-allow-sms']:checked").val(),
    current_flex = submit.parents('._fg-editable'),
    delete_meta = false;

  // Sanitize SMS to match assertion
  if (meta_key == 'wpcf-sms') {
    meta_value = sanitize_sms(meta_value);
    original_text = sanitize_sms(original_text);
  }

  if (meta_value == original_text) {
    cancel.trigger('click', { manual: 'triggered' });
    return false;
  }

  if (!meta_value || meta_value.trim().length < 1) {
    delete_meta = true;
  }

  if (meta_key == 'wpcf-rsvp-count') {
    if (meta_value == '' || meta_value == '0') {
      $('#_fg-temp-input').val('1');
      meta_value = '1';
    }

    // Don't allow additionals
    if (is_waitlist_in_effect() && $('body.party-orig').length && original_text < meta_value) {
      cancel.trigger('click', { manual: 'triggered' });
      return fg_alert('No additions while wait list is in effect');
    }
  }

  (async () => {
    /* Before Send init */
    label.append(progressIcon(Globals.STYLES.progress));

    try {
      const response = await ajaxPromise({
        post_id: post_id,
        contact_id: contact_id,
        meta_key: meta_key,
        meta_value: meta_value,
        allow_sms: allow_sms,
        delete: delete_meta,
        action: action
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        submit
          .siblings('._fg-inline-value-placeholder')
          .html(original_text)
          .removeClass('d-none')
          .end()
          .before(progressIcon('error'));
        the_alert.html(response.msg);
        submit.parents('._fg-editable').append(the_alert);
        return false;
      }

      /* Success status condition */
      if (response.data && response.data['contact_id']) {
        submit.parents('._fg-edit-inline').attr('data-contact-id', response.data['contact_id']);
      }
      // Setting sms means you must be ok, this is set on server
      // side before returning
      if (meta_key == 'wpcf-sms') {
        let is_null = meta_value.trim() === '';
        $('#_input-allow-sms')
          .prop('checked', !is_null)
          .toggleClass('selected', !is_null);
        $('#_input-disallow-sms')
          .prop('checked', is_null)
          .toggleClass('selected', is_null);
      }

      inline_placeholder.toggleClass('text-muted', !meta_value);

      submit
        .siblings('._fg-inline-value-placeholder')
        .html(meta_value.trim() == '' ? NOT_SET : meta_value)
        .removeClass('d-none')
        .end()
        .before(progressIcon('success'));

      if (meta_key == 'wpcf-rsvp-count') {
        reload_rsvp_counts();
      }
      if (meta_key.match(/wpcf-(first-name|location|age|rsvp-count)/)) {
        load_grid();
      }
    } catch (e) {
      /* Failure condition */
      submit
        .siblings('._fg-inline-value-placeholder')
        .html(original_text)
        .removeClass('d-none')
        .end()
        .before(progressIcon('error'));
    } finally {
      // Always (finally)
      pencil.removeClass('d-none');
      submit.addClass('d-none');
      cancel.addClass('d-none');
      label.removeClass('d-none');
      fade_other_inputs(current_flex, true);
      handle_user_input_groups('on');
      $('#_fg-temp-input').remove();
    }
  })();
}

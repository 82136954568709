import Navigator from '@lib/navigator';
import { clearTimers, set_selected_party } from '@lib/shared/util';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
    var btn = $(currentTarget),
        selected_party = btn.attr('data-party-id');

    const xref = ajax_obj.home_url + '/party-orig/?p_id=' + selected_party,
          template = 'party-orig';


    // DELETEME $('#fg-delegate').attr('party-toggle', selected_party);

    // Set the selected party
    set_selected_party( selected_party );

    const nav = new Navigator( xref, template );

    // Kill all the REST timers!
    clearTimers();

    // Empty original_grid
    Globals.ORIGINAL_GRID = new DocumentFragment();
    Globals.LOCKED_CANDIDATES = new DocumentFragment();
    Globals.LOCKED_FILTERS = [];

    nav.go();
}


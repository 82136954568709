import { ajaxPromise } from '@lib/shared/ajax';
import { waiting, done_waiting, animateCSS } from '@lib/shared/util';
import Globals from '@lib/globals';

export default function(e, ct) {
  var btn_face = $(ct),
    data_elem = btn_face.parents('._image-management-data'),
    image_id = data_elem.attr('data-other-images-id'),
    a_id = data_elem.attr('data-attendee-id'),
    face_showing = data_elem.data('face-showing');

  (async () => {
    /* Before Send init */
    waiting(btn_face);

    try {
      const response = await ajaxPromise({
        image_id: image_id,
        a_id: a_id,
        face_showing: face_showing ? '0' : '1',
        action: 'set_face_on_image'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_console(response.data);
        animateCSS($(btn_face).html(response.msg), 'bounce');
        return false;
      }

      /* Success status condition */
      btn_face.attr('data-toggle', 'tooltip').attr('data-original-title', (i, currentClass) => {
        var current = toggle => (toggle ? 'hidden' : 'visible');
        return 'Current state is ' + current(face_showing) + ' face, click to make ' + current(!face_showing);
      });
      done_waiting(btn_face, response.msg);
      data_elem.data('face-showing', face_showing ? false : true);
    } catch (e) {
      /* Failure condition */
      animateCSS($(btn_face).html(e.message), 'bounce');
    }
  })();
}

import { locked_candidates } from '@lib/shared/filtering';
import Globals from '@lib/globals';
export default function(e, ct) {
  var btn = $(ct),
    locked = btn.attr('data-lock') != 'false',
    icon = $(`<i class="fas _icon pr-2"></i>`);
  icon.toggleClass('fa-lock', !locked).toggleClass('fa-unlock', locked);
  btn
    .find('._icon')
    .remove()
    .end()
    .html(locked ? 'Unlocked' : 'Locked')
    .attr('data-lock', !locked)
    .toggleClass('btn-danger', !locked)
    .prepend(icon);
  locked_candidates(!locked ? $('._grid__brick').get() : []);
}

import { sortByRsvpTimestamp } from '@lib/shared/state';
import {
  set_selected_party,
  httpBuildQuery,
  get_parties_attended,
  get_rsvp_icon,
  get_selected_party
} from '@lib/shared/util';
import Globals from '@lib/globals';
import { rest_entry_point } from '@lib/shared/ajax';
import { searchAndRender, add_filter_breadcrumb, update_elements_after_filter } from '@lib/shared/filtering';

export default function(e, ct) {
  // Clear the rsvp filters
  var the_select = $(ct),
    current_party = get_selected_party(),
    selected_party = the_select.find('option:selected').val(),
    selected_party_human = the_select.find('option:selected').html();
  $('#fg-main-grid').attr('data-party-id', selected_party);
  set_selected_party(selected_party);
  $('._rsvp-indicator').remove();
  var reset = selected_party == 0;

  // Load up coverage
  var data = {
    p_id: selected_party
  };
  rest_entry_point('sg-events-coverage/?' + httpBuildQuery(data), true)
    .then(response => {
      ajax_obj.sg_events_coverage = response.response;
    })
    .catch(error => {
      fg_error(error);
    });

  const args = {
    reset: reset,
    matchFunction: function(element) {
      var a_id = $(element)
          .find('._fg-modal-on-demand')
          .attr('data-attendee-id'),
        party_meta = get_parties_attended(a_id, selected_party),
        matches = party_meta || false;

      if (party_meta) {
        $(element)
          .find('._fg-thumb')
          .after(get_rsvp_icon(party_meta['reply_n'], a_id));
      }

      if (matches) {
        var is_host = party_meta && party_meta['is_host'];
        if (is_host) {
          $(element)
            .find('._fg-thumb')
            .append(`<span class="${Globals.STYLES.host} _host">HOST</span>`);
        } else {
          $(element)
            .find('._host')
            .remove();
        }
        add_filter_breadcrumb(selected_party_human, Globals.FILTER_PARTY, selected_party);
      }

      return matches !== false;
    },
    sortFunction: sortByRsvpTimestamp
  };
  searchAndRender(args)
    .then(locked_candidates => update_elements_after_filter())
    .catch(err => fg_error(err));

  // Sync other selects
  $('._party-select-dropdown')
    .not(the_select)
    .val(selected_party || 0);
  $('._printParty > div:nth-child(2)')
    .toggleClass('d-none', current_party == selected_party)
    .find('.selected')
    .html($('#_partySelectDropdown option:selected').html())
    .toggleClass('text-success', current_party != selected_party);
}

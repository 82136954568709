import { get_rsvp_count } from '@lib/shared/util';
import { load_grid, log_analytic } from '@lib/shared/ajax';
import { original_grid, locked_candidates } from '@lib/shared/filtering';

export default function(e, currentTarget) {
  var clicked = $(currentTarget),
    timeToReload = () => {
      var red_dot = $('#_time-to-reload'),
        total_rsvp_count = get_rsvp_count(),
        delta = 0;
      red_dot
        .html(delta)
        .addClass('d-none')
        .attr('data-last-count', total_rsvp_count);
      clicked.removeClass('fg-btn-selected');
    };

  // Remove any open breadcrumbs
  $('#filterBreadcrumb')
    .addClass("d-none")
    .children()
    .remove();

  load_grid( true ).then(context => {
    timeToReload();
  });
  log_analytic('refresh-grid', 'Refresh');
}

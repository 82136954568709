import {
  reset_filter_rsvp,
  searchAndRender,
  update_elements_after_filter,
  add_filter_breadcrumb
} from '@lib/shared/filtering';
import { log_google_analytics, bounceBack, menuOffset } from '@lib/shared/util';
import { log_analytic } from '@lib/shared/ajax';
import GA from '@lib/ga';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
  var btn = $(currentTarget),
    filterby = btn.attr('data-term'),
    reset = btn.is('button._btn-position-reset'); // just reuse it
  if (btn.hasClass('disabled')) {
    return false;
  }
  reset_filter_rsvp(btn).then(itm => {
    log_google_analytics($(btn), function(element) {
      return GA.dispatch('clickFilter', 'Filter Tags', filterby || 'reset');
    });
    log_analytic('filter-tag', filterby || 'reset');
    add_filter_breadcrumb(filterby, Globals.FILTER_TAG, filterby);

    const args = {
      reset: reset,
      matchFunction: function(element) {
        if (reset) return reset;
        var terms = $(element)
          .find('._fg-thumb')
          .attr('data-terms');
        return terms && terms.indexOf(filterby) > -1;
      }
    };

    let filterTop = $('#filterBreadcrumb').length ? $('#filterBreadcrumb') : $('._fg-grid-wrapper');
    searchAndRender(args)
      .then(locked_candidates => update_elements_after_filter())
      .then(() => bounceBack(filterTop.offset().top - 20))
      .catch(err => fg_error(err));
  });
}

import { ajaxPromise, load_note_history } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { get_selected_r_id } from '@lib/shared/util';
import { RenderLazy } from '@lib/shared/render';

export default function(e, ct) {
  var clicked = $(ct),
    meta_key = clicked.attr('data-meta-key'),
    post_id = clicked.parents('._fg-edit-post').attr('data-post-id') || get_selected_r_id(),
    textarea = $(`#_chat-input-${post_id}`),
    meta_value = textarea.val();

  if (!meta_value || meta_value.trim() == '') {
    return false;
  }

  (async () => {
    /* Before Send init */
    clicked.addClass('disabled').html(`<i class="fas fa-circle-notch fa-spin"></i>`);
    $("#chatError").remove();

    try {
      const response = await ajaxPromise({
        post_id: post_id,
        meta_key: meta_key,
        meta_value: meta_value,
        action: 'user_edit_attendee_meta'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        // Do something like red-ify the textarea
        return false;
      }

      /* Success status condition */
      if (response.data && response.data.single_chat_message) {
        const container = $(`#_chatContainer-${post_id}`);
        if (container.length > 0) {
          let config = {
            templatePath: 'partials/chat-single-message',
            context: response.data.single_chat_message,
            node: container,
            append: true
          };

          // Just append
          await RenderLazy(config);
          container[0].scrollTop = container[0].scrollHeight;
          textarea.val('');
          clicked.removeClass('disabled').html('Send');
        } else {
          // Otherwise must paint a new chat container
          await load_note_history();
        }
      }
    } catch (e) {
      /* Failure condition */
      clicked.removeClass('disabled').html('Send').after(`<small class="text-danger" id="chatError">An error occurred, try again.</small>`);
    }
  })();
}

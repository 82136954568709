import { set_cookie, httpBuildQuery, clearTimers, clear_parties } from '@lib/shared/util';
import Navigator from '@lib/navigator';
import Globals from '@lib/globals';

export default function (e, target) {
  const error_message = "You have been signed out";
  logout_user( error_message );
}
export function logout_user ( msg ) {
  // Delete cookie
  set_cookie('_fgl_logged_in');

  // Clear the parties
  clear_parties();

  // Navigate to Reset
  const qs = {
    message: "Logged Out",
    error: msg
  },
   href = ajax_obj.home_url + '/user-error/?' + httpBuildQuery(qs),
        template = 'user-error',
        nav = new Navigator( href, template );

  // Kill all the REST timers!
  clearTimers();

  // hardReload
  nav.go( true );
}

import { public_section_swap, control_history, log_google_analytics, get_selected_r_id } from '@lib/shared/util';
import { log_analytic } from '@lib/shared/ajax';
import GA from '@lib/ga';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
  var menu_btn = $(currentTarget),
    section_to_show = $(menu_btn).attr('data-toggle'),
    r_id = get_selected_r_id();

  // Track menu history
  control_history(section_to_show, 'menu');

  // Real link
  if (typeof section_to_show === 'undefined') return true;

  // Already active
  if ($(menu_btn).hasClass('active')) return false;

  public_section_swap(section_to_show, 0);

  // Clear the actives and set the active
  $('._fg-public-menu a').removeClass(`active ${Globals.STYLES.active} fg-btn-selected ${Globals.STYLES.fgButtonSelected}`);
  $("._fg-public-menu a[data-toggle='" + section_to_show + "']").addClass(`active ${Globals.STYLES.active} fg-btn-selected ${Globals.STYLES.fgButtonSelected}`);

  // In case an editable was left open
  $(`body._faded-edit ._fg-editable:not(.${Globals.STYLES.fadeAndBlur})`)
    .find('._fg-edit-inline-btn-cancel')
    .trigger('click', {manual: "triggered"});

  // In case enlarge was left open
  let enlarge = $('#_fg-enlarge._enlarge-active');
  if (enlarge.length) {
    $('#gridDetail').removeClass(Globals.STYLES.enlarge);
    $('#_fg-enlarge').removeClass('animated fadeIn _enlarge-active');
    $('.dropzone').removeClass('d-none');
  }

  // Re-align rsvp notes
  const container = $(`#_chatContainer-${r_id}`);
  if ( container.length ) {
    container[0].scrollTop = container[0].scrollHeight;
  }

  log_google_analytics(menu_btn, function(element) {
    log_analytic('menu', section_to_show);
    return GA.dispatch('menuClicked', section_to_show);
  });
}

import {
  control_history,
  animateCSS,
  attendee_master_section_swap,
  fix_lowres_images,
  bounceBack,
  httpBuildQuery,
  get_current_user,
  get_selected_party,
  preload_enlargeable_images
} from '@lib/shared/util';
import {
  user_endpoint_init,
  user_profile_page_init,
  listen_for_card_expansions,
  grid_item_progress
} from '@lib/shared/state';
import { RenderLazy } from '@lib/shared/render';
import Globals from '@lib/globals';
import loadModule from '@lib/shared/loadModule';

export default function(e, ct) {
  var clicked = $(ct),
    picture = $(ct).find('picture'),
    on_demand = clicked.siblings('._fg-modal-on-demand'),
    a_id = on_demand.data('attendee-id'),
    container = $('#_fg-attendee-detail'),
    scroll = $(window).scrollTop();
  control_history('#' + a_id, 'detail');
  container.attr('data-attendee-id', a_id);

  let config = {
    templatePath: 'attendee-master/atm-grid-detail',
    context: [
      'ui-admin-grid-detail/?' +
        httpBuildQuery({
          a_id: a_id,
          scroll: scroll
        }),
      'ui-crud-user-profile/?' +
        httpBuildQuery({
          namespace: 'user_profile',
          a_id: a_id
        }),
      'ui-picture-engine/?' +
        httpBuildQuery({
          namespace: 'tiny_image_grid',
          image_size: 'small',
          a_id: a_id
        }),
      'ui-picture-engine/?' +
        httpBuildQuery({
          a_id: a_id,
          namespace: 'image_management',
          image_size: 'small-full'
        }),
      {
        response: {
          parties_attended: ajax_obj.parties_attended.filter(rec => rec.a_id == a_id)
        }
      }
    ],
    node: container
  };

  grid_item_progress(picture);

  RenderLazy(config)
    .then(context => {
      grid_item_progress(picture);
      after_atm_grid_detail_loads();
      listen_for_card_expansions();
      preload_enlargeable_images(container);
    })
    .finally(() => {});
}

function after_atm_grid_detail_loads() {
  var attendee_detail = $('#_fg-attendee-detail'),
    section_to_show = $('._fg-attendee-master-section-detail'),
    accordion_collapse = function(event) {
      var scroll = $(this).hasClass('show') ? $(this).offset().top - 80 : 0;
      bounceBack(scroll);
      return false;
    },
    a_id = attendee_detail.attr('data-attendee-id'),
    rating = attendee_detail.find('._rating').attr('data-rating'),
    stars = attendee_detail.find('._star-rating');

  attendee_master_section_swap(section_to_show);

  user_profile_page_init();

  // why is there necessary?
  $('#parties-attended-accordion > .card > .card-header > [data-toggle="collapse"]').click(function(e) {
    var href = $(this).attr('data-target');
    $(href).on('shown.bs.collapse', accordion_collapse);
  });
  // user_endpoint_init();
  fix_lowres_images();

  (async function() {
    var ClipboardJS = await loadModule('clipboard');

    var clipboard = new ClipboardJS.default('._fg-copy-click');
    clipboard.on('success', function(event) {
      var clicked = $(event.trigger);
      animateCSS(clicked, 'fadeOutUp faster').then(message => {
        event.clearSelection();
      });
    });
  })();

  (function() {
    $(window).keyup(e => {
      if (!$('.fg-attendee-master-section-detail').hasClass('d-none') && (e.key == 'escape' || e.keyCode == 27)) {
        $('._fg-back-to-grid').trigger('click', { manual: 'triggered' });
      }
    });
  })();
}

import { get_selected_party, httpBuildQuery, bounceBack } from '@lib/shared/util';
import { RenderLazy } from '@lib/shared/render';

export default function(e, ct) {
  var btn = $(ct),
    a_id = btn.attr('data-attendee-id'),
    p_id = btn.is('._fg-show-love-connections') ? get_selected_party() : '',
    qs = {
      p_id: p_id,
      a_id: a_id,
      is_featured_image: true
    },
    target = $('#_love-connection-' + a_id);

  (async t => {
    let config = {
      templatePath: 'attendee-master/atm-love-connections',
      context: 'view-stats/?' + httpBuildQuery(qs),
      node: target
    };
    await RenderLazy(config);
    bounceBack(t.offset().top);
  })(target);
}

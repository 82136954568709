import { get_selected_party, fg_alert, get_parties_attended, get_rsvp_icon } from '@lib/shared/util';
import { sortByRsvpTimestamp } from '@lib/shared/state';
import { searchAndRender, add_filter_breadcrumb, update_elements_after_filter } from '@lib/shared/filtering';
import Globals from '@lib/globals';

export default function(e, ct) {
  var clicked = $(ct),
    is_notif_status = clicked.hasClass('_notif-status-filter'),
    p_id = get_selected_party(),
    data_value = is_notif_status ? 'notif_status' : 'reply_n',
    clicked_value = is_notif_status ? clicked.attr('data-notification-status') : clicked.attr('data-reply'),
    reply_human = clicked.html();

  if (!p_id) {
    return fg_alert('Select a Party');
  }

  add_filter_breadcrumb(reply_human, Globals.FILTER_REPLY, clicked_value);

  const args = {
    matchFunction: function(element) {
      var a_id = $(element)
          .find('._fg-modal-on-demand')
          .attr('data-attendee-id'),
        party_meta = get_parties_attended(a_id, p_id);

      if (party_meta) {
        $(element)
          .find('._fg-thumb')
          .after(get_rsvp_icon(party_meta['reply_n'], a_id));
      }

      return party_meta && party_meta[data_value] == clicked_value;
    },
    sortFunction: sortByRsvpTimestamp
  };
  
  searchAndRender(args)
    .then(locked_candidates => update_elements_after_filter())
    .catch(err => fg_error(err));
}

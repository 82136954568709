import { show_sms_modal, log_google_analytics, set_current_party, valid_email, animateCSS } from '@lib/shared/util';
import { ajaxPromise } from '@lib/shared/ajax';
import GA from '@lib/ga';
import Globals from '@lib/globals';
import styles from '@scss/template/_alerts.module.scss';

export default function(e, ct, arb_args) {
  let parent = arb_args.parent;
  var login_btn = $(ct),
    email_input = parent.find('input[type="email"]'),
    password_input = parent.find('input[type="password"]'),
    email = email_input.val().trim(),
    email_is_valid = valid_email(email),
    password = password_input.val(),
    fragment = login_btn.attr('data-fragment'),
    redirect = login_btn.attr('data-redirect') || null,
    alert_box = parent.find('._fg-alert'),
    attempt = login_btn.data('attempt') || 1,
    show_alert = alert_msg => {
      $('.loginComponent .fa-lock').addClass('text-danger');
      login_btn.html('Sign In');
      alert_box
        .html(alert_msg)
        .removeClass('d-none')
        .addClass('');
      animateCSS(alert_box, 'shakeX').then(() => {
        // Increment attempt
        login_btn.data('attempt', ++attempt);
      });
      return false;
    };

  alert_box.addClass('d-none');

  if (false === email_is_valid) {
    return show_alert('Invalid credentials');
  }

  const submitAjax = async token => {
    parent.find('.fa-lock').removeClass('text-danger text-success');
    alert_box.addClass('d-none');
    login_btn.html('<i class="far fa-compass fa-spin" style="font-size: 1.25em"></i>');
    try {
      const response = await ajaxPromise({
        email: email.trim(),
        password: password.trim(),
        fragment: fragment,
        login_redirect: redirect,
        action: 'password_authenticate',
        grecaptcha_token: token
      });

      if (response.status === Globals.STATUS_ERROR) {
        return show_alert(response.msg);
      }

      parent
        .find('.fa-lock')
        .removeClass('fa-lock')
        .addClass('fa-unlock text-success');

      login_btn.html(response.msg);

      // One of few places we set the party
      set_current_party(response.data.p_id);

      setTimeout(function() {
        show_sms_modal(response.data);
      }, 1000);

      log_google_analytics(login_btn, function(element) {
        const loginStatus = Globals.STATUS_SUCCESS == status ? 'success' : response.msg;
        return GA.dispatch('userAccess', response.data.attendee_id || email, 'Login', loginStatus);
      });
    } catch (e) {}
  };

  if (attempt > 3) {
    grecaptcha.ready(function() {
      // do request for recaptcha token
      // response is promise with passed token
      grecaptcha
        .execute(ajax_obj.RECAPTCHA_SITE_KEY, {
          action: 'handle_user_login'
        })
        .then(function(token) {
          submitAjax(token);
        })
        .catch(error => {
          submitAjax(0); // disables grecaptcha and lets it go through
          fg_error(error, 'Error during grecaptcha');
        });
    }); // end ready
  } else {
    submitAjax(0);
  }
}

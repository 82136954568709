import { fade_other_inputs, handle_user_input_groups } from '@lib/shared/util';

export default function(e, currentTarget) {
  const NOT_SET = "Not set";

  var cancel = $(currentTarget),
    pencil = cancel.siblings('._fg-edit-inline-btn-pencil'),
    submit = cancel.siblings('._fg-edit-inline-btn-submit'),
    original_text = cancel.siblings('._fg-inline-value-placeholder').attr('data-original-text'),
    original_text = original_text.trim() == "" ? NOT_SET : original_text,
    label = cancel.parents('._fg-editable').find('label'),
    current_flex = $(cancel).parents('._fg-editable');
  $('#_fg-temp-input')
    .siblings('._fg-inline-value-placeholder')
    .html(original_text)
    .removeClass('d-none')
    .end()
    .remove();

  pencil.removeClass('d-none');
  submit.addClass('d-none');
  cancel.addClass('d-none');
  label.removeClass('d-none');
  fade_other_inputs(current_flex, true);
  handle_user_input_groups('on');
}

import { waiting, done_waiting, animateCSS } from '@lib/shared/util';
import Globals from '@lib/globals';
import { ajaxPromise } from '@lib/shared/ajax';

export default function(e, ct) {
  var btn = $(ct),
    data_elem = btn.parents('._image-management-data'),
    a_id = data_elem.attr('data-attendee-id'),
    thumb_id = data_elem.attr('data-thumb-id');

  (async () => {
    /* Before Send init */
    waiting(btn);

    try {
      const response = await ajaxPromise({
        a_id: a_id,
        thumb_id: thumb_id,
        action: 'make_featured_image'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_console(response.data);
        animateCSS($(btn).html(response.msg), 'bounce');
        return false;
      }

      /* Success status condition */
      done_waiting(btn, response.msg);
      btn.addClass('_featured btn-success').removeClass('btn-default');
      $('._make-featured')
        .not('._featured')
        .html('Make featured')
        .removeClass('btn-success')
        .addClass('btn-default');
    } catch (e) {
      /* Failure condition */
      animateCSS($(btn).html(e.message), 'bounce');
    }
  })();
}

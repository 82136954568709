import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { fg_alert } from '@lib/shared/util';

export default function(e, ct) {
  var btn = $(ct),
    p_id = $('#_partySelectInitRSVP').val(),
    test = $(btn).hasClass('_fg-init-rsvp-test'),
    min_rating = $('#_init-rsvp-min-rating').val(),
    the_alert = $('._fg-init-rsvp ._fg-alert'),
    results = $('._fg-init-rsvp-results');
  if (!p_id) {
    return fg_alert('Missing party_id');
  }

  (async () => {
    /* Before Send init */
    results.children().remove();
    the_alert.addClass('d-none');

    try {
      const response = await ajaxPromise({
        p_id: p_id,
        test: test,
        rating: min_rating || 3,
        action: 'init_rsvp'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        the_alert
          .removeClass(`${Globals.STYLES.fgAlert_success} d-none`)
          .addClass(`${Globals.STYLES.fgAlert_danger}`)
          .html(response.msg);
        return false;
      }

      /* Success status condition */
      the_alert
        .removeClass(`${Globals.STYLES.fgAlert_danger} d-none`)
        .addClass(`${Globals.STYLES.fgAlert_success}`)
        .html(response.msg);
      var buffer = '';
      $.each(response.data, function(idx, itm) {
        if (!itm.ID) return;
        buffer += '<span>' + itm.ID + '</span>';
      });
      results.append(buffer);
    } catch (e) {
      /* Failure condition */
      the_alert
        .removeClass(`d-none ${Globals.STYLES.fgAlert_success}`)
        .addClass(`${Globals.STYLES.fgAlert_danger}`)
        .html(e.message);
    }
  })();
}

import {
  reset_filter_rsvp,
  add_filter_breadcrumb,
  update_elements_after_filter,
  searchAndRender
} from '@lib/shared/filtering';
import { log_google_analytics, bounceBack, menuOffset } from '@lib/shared/util';
import { log_analytic } from '@lib/shared/ajax';
import GA from '@lib/ga';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
  var btn = $(currentTarget);
  if (btn.hasClass('disabled')) {
    return false;
  }
  reset_filter_rsvp(btn).then(itm => {
    var filterby = btn.attr('data-name'),
      reset = btn.is('button._btn-position-reset'),
      matches = false;
    log_google_analytics($(btn), function(element) {
      return GA.dispatch('clickFilter', 'Filter Position', filterby || 'reset');
    });
    log_analytic('filter-position', filterby || 'reset');
    if (reset) {
      $('#filterBreadcrumb')
        .addClass('d-none')
        .children()
        .remove();
    } else {
      add_filter_breadcrumb(filterby, Globals.FILTER_POSITION, filterby);
    }
    const args = {
      reset: reset,
      matchFunction: function(element) {
        if (reset) return reset;
        var pos = $(element)
          .find('._fag-position')
          .attr('data-name');
        return pos == filterby;
      }
    };
    let filterTop = $('#filterBreadcrumb').length ? $('#filterBreadcrumb') : $('._fg-grid-wrapper');
    searchAndRender(args)
      .then(locked_candidates => update_elements_after_filter())
      .then(() => bounceBack(filterTop.offset().top - 20))
      .catch(err => fg_error(err));
  });
}

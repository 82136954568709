import {
  progressIcon,
  fg_alert,
  fade_other_inputs,
  animateCSS,
  handle_user_input_groups
} from '@lib/shared/util';

import Globals from '@lib/globals';

export default function(e, currentTarget) {
  const NOT_SET = "Not set";
  var inline = $(currentTarget),
    placeholder = inline.find('._fg-inline-value-placeholder'),
    pencil = inline.find('._fg-edit-inline-btn-pencil'),
    submit = inline.find('._fg-edit-inline-btn-submit'),
    cancel = inline.find('._fg-edit-inline-btn-cancel'),
    original_text = placeholder.html().trim() == NOT_SET ? "" : placeholder.html(),
    current_flex = cancel.parents('._fg-editable'),
    format_mask = '';

  // remove any icons looming
  progressIcon();

  // Can't click if blurred or if clicking an already open input
  if (
    inline.parents('._fg-editable').hasClass(Globals.STYLES.fadeAndBlur) ||
    inline.find('input').length > 0 ||
    $(e.target).is('#_fg-temp-input')
  ) {
    return false;
  }

  $(
    '#_fg-temp-input, #_fg-alert-id, ._fg-public-section-profile ._fg-edit-post ._fg-alert, ._fg-edit-post .thumbs-up, ._fg-public-section-profile .fa-thumbs-up, ._fg-public-section-profile .temp-spinner'
  ).remove();

  var input_type = 'text';
  placeholder.attr('data-original-text', original_text);
  if (inline.hasClass('_fg-user-sms')) {
    format_mask = 'onkeyup="formatPhone(this)"';
    input_type = 'tel';
  }
  if (inline.hasClass('_fg-user-age')) {
    format_mask = 'min="18" max="60" onkeyup="formatInt(this, 2)"';
    input_type = 'number';
  }
  if (inline.hasClass('_fg-user-rsvp-count')) {
    format_mask = 'min="1" max="5" onkeyup="formatInt(this, 1)"';
    input_type = 'number';
  }
  pencil.addClass('d-none');
  submit.removeClass('d-none');
  cancel.removeClass('d-none');
  fade_other_inputs(current_flex);

  if (inline.hasClass('_fg-user-note')) {
    inline.prepend(
      `<textarea id="_fg-temp-input" style="width: 100%" name="edit-inline" class="_fg-edit-inline-input form-control" rows="3" data-module="handle_inline_cancel">${original_text}</textarea>`
    );
  } else {
    inline.prepend(`
      <input id="_fg-temp-input" ${format_mask}
        class="_fg-edit-inline-input form-control"
        type="${input_type}"
        name="edit-inline"
        value="${original_text}">`);
  }

  animateCSS(inline, 'fadeIn').then(() => {
    $('#_fg-temp-input').focus();
  });
  if ($('#_fg-temp-input').is('._fg-user-sms ._fg-edit-inline-input')) {
    if (original_text != '') {
      $('#_fg-temp-input').trigger('keyup');
    }
  }
  placeholder.addClass('d-none');

  handle_user_input_groups('off');
}

import { ajaxPromise } from '@lib/shared/ajax';
import { RenderLazy } from '@lib/shared/render';
import Globals from '@lib/globals';
import { httpBuildQuery } from '@lib/shared/util';

export default function(e, ct) {
  var clicked = $(ct),
    action = clicked.attr('data-action'),
    a_id = $('#atmContacts').attr('data-attendee-id'),
    contact_id = clicked.attr('data-contact-id') || false,
    target = clicked.attr('data-target'),
    contact_type =
      $('#atmContacts')
        .find('.wpcf-contact-type > option:selected')
        .val() || false,
    contact_value = $('#atmContacts')
      .find('.wpcf-contact-value')
      .val();

  const removeContact = () => {
    if (contact_id) {
      submit_ajax({
        action: 'manage_contacts',
        delete: true,
        contact_id: contact_id
      });
    } else {
      fg_error('Missing contact_id');
    }
  };

  const saveContact = () => {
    if (contact_type && contact_value && a_id) {
      submit_ajax({
        action: 'manage_contacts',
        a_id: a_id,
        wpcf_contact_type: contact_type,
        wpcf_contact_value: contact_value
      });
    } else {
      fg_error('Missing data to submit to ajax');
    }
  };

  const submit_ajax = async request_data => {
    try {
      const response = await ajaxPromise(request_data);

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        fg_error(response);
        return false;
      }

      let config = {
        templatePath: 'partials/crud-contacts',
        context: [
          'ui-crud-user-profile/?' + httpBuildQuery({ a_id: a_id }),
        ],
        node: $('#atmContacts'),
      };

      /* Success status condition */
      RenderLazy(config);
    } catch (e) {
      /* Failure condition */
    }
  };

  switch (action) {
    case 'add':
      $(target).removeClass('invisible');
      break;
    case 'remove':
      removeContact(contact_id);
      break;
    case 'save':
      saveContact(a_id);
      break;
    default:
      fg_error('Invalid action');
      return false;
  }
}

import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import atmOverlays from '@template/partials/atmOverlays';
export default function(e, ct) {
  var btn = $(ct),
    a_id = btn.attr('data-attendee-id'),
    current_state = btn.hasClass('publish') ? 'publish' : 'pending',
    future_state = current_state == 'publish' ? 'pending' : 'publish',
    state = {
      publish: {
        html: 'Published',
        button: 'btn-success'
      },
      pending: {
        html: 'Pending',
        button: 'btn-danger'
      }
    };

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'publish_attendee',
        a_id: a_id,
        post_status: future_state
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        btn
          .removeClass('btn-success')
          .addClass('btn-danger')
          .html(response.msg);
        return false;
      }

      /* Success status condition */
      btn
        .removeClass(current_state)
        .removeClass(state[current_state].button)
        .addClass(future_state)
        .addClass(state[future_state].button)
        .html(state[future_state].html);
      var featured_img = $(`#_featured-image-${a_id}`),
        newIconOverlay = atmOverlays({ post_status: future_state }, Globals.STYLES);
      featured_img
        .find('._icon-overlay')
        .remove()
        .end()
        .append(newIconOverlay)
        .find('picture')
        .attr('data-post-status', future_state);
    } catch (e) {
      /* Failure condition */
      btn
        .removeClass('btn-success')
        .addClass('btn-danger')
        .html(textStatus);
    }
  })();
}

import { RenderLazy } from '@lib/shared/render';
import { httpBuildQuery, bounceBack, get_selected_party, fg_alert } from '@lib/shared/util';
import Globals from '@lib/globals';

export default function(e, ct) {
  var clicked = $(ct),
    last_seen = clicked.attr('data-last-seen'),
    target = clicked.attr('data-target'),
    scroll = clicked.offset().top;

  const load_moreable_template = {
    '#_launch-sg-log': {
      template: 'launch/launch-panel-sglog',
      endpoint: [
        'ui-sg-event-log/?' +
          httpBuildQuery({
            last_seen_id: last_seen
          })
      ]
    },
    '#loadMoreRSVPConsole': {
      template: 'launch/rsvp-console-grid-items',
      endpoint: [
        'ui-rsvp-console/?' +
          httpBuildQuery({
            p_id: get_selected_party(),
            reply: $('#rsvpConsoleFilterButtons button.selected').attr('data-reply') || null,
            rating: $('#rsvpConsoleFilterAttendee button.selected').attr('data-rating') || null,
            notification_status:
              $('#rsvpConsoleFilterButtons button.selected').attr('data-notification-status') || null,
            last_seen_id: last_seen
          }),
        'ui-misc/?' +
          httpBuildQuery({
            namespace: 'last_correspondence_interaction'
          }),
        'ui-misc/?' +
          httpBuildQuery({
            namespace: 'unsent_notification_counts',
            p_id: get_selected_party()
          }),
        { response: { picture_data: Globals.FEATURED_IMAGES } }
      ]
    },
    '#_analytics-grid': {
      template: 'partials/analytics-console',
      endpoint: [
        'analytics/?' +
          httpBuildQuery({
            last_seen_id: last_seen,
            is_featured_image: true,
            p_id: get_selected_party(),
            namespace: 'analytics'
          }),
        'ui-active-tokens',
        { response: { picture_data: Globals.FEATURED_IMAGES } }
      ]
    }
  };

  var the_one = load_moreable_template[target],
      old_load_more = new Date().getTime();

  $('._load-more').attr("id", old_load_more ).addClass('invisible');

  if (!the_one) {
    throw new Error('Cannot load more');
  }

  (async s => {
    try {
      let config = {
        templatePath: the_one.template,
        context: the_one.endpoint,
        node: $(target),
        append: true
      };

      const context = await RenderLazy(config);

      $("#" + old_load_more).remove();

    } catch (e) {
      if (e.message == rest_obj.response_codes.REST_NO_DATA) {
        return fg_alert('No more data');
      }
    }
    bounceBack(s);
  })(scroll);
}

import { get_selected_party, httpBuildQuery } from '@lib/shared/util';
import { RenderLazy } from '@lib/shared/render';
import Globals from '@lib/globals';
import { reload_rsvp_counts } from '@lib/shared/ajax';

export default function(e, ct) {
  var current_target = $(ct),
    p_id = current_target.find('option:selected').val() || get_selected_party(),
    a_id = current_target.attr('data-attendee-id') || $('#_rsvp_console_a_id_search').val() || null,
    reply_n = current_target.attr('data-reply') || null,
    rating = current_target.attr('data-rating') || null,
    notif_status = current_target.attr('data-notification-status') || null;

  // Save state of filtering if necessary
  $('#rsvpConsoleFilterButtons button').removeClass('btn-success');
  current_target.addClass('btn-success');
  $('#loadMoreRSVPConsole').addClass('animated fadeOut');

  let config = {
    templatePath: 'launch/launch-panel-rsvp-console',
    context: [
      'ui-rsvp-console/?' +
        httpBuildQuery({
          p_id: p_id,
          a_id: a_id,
          reply: reply_n,
          notification_status: notif_status,
          rating: rating
        }),
      'ui-misc/?' +
        httpBuildQuery({
          namespace: 'unsent_notification_counts',
          p_id: get_selected_party()
        }),
      { response: { picture_data: Globals.FEATURED_IMAGES } }
    ],
    node: $('#_launch-rsvp-console')
  };

  (async () => {
    await reload_rsvp_counts();
    RenderLazy(config);
  })();
}

import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { fg_alert, are_you_sure } from '@lib/shared/util';

export default function(e, ct) {
  var btn = $(ct);

  const callback = async () => {
    /* Before Send init */
    btn.removeClass('btn-success btn-danger').addClass('btn-default outline');

    try {
      const response = await ajaxPromise({
        action: 'clear_generic_indicators'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        btn
          .removeClass('btn-default outline')
          .addClass('btn-danger')
          .html('Error');
        return false;
      }

      /* Success status condition */
      btn
        .addClass('btn-success')
        .removeClass('btn-default outline')
        .html(response.msg);
    } catch (e) {
      /* Failure condition */
      return fg_alert('Error resetting');
    }
  };

  are_you_sure(
    'clear the indicators? You will not be able to distinguish who was sent the last generic email.',
    callback
  );
}

import {
  get_current_user,
  get_pic_count,
  public_section_swap,
  control_history,
  log_google_analytics,
  fg_alert,
  fix_lowres_images,
  preload_enlargeable_images
} from '@lib/shared/util';
import Globals from '@lib/globals';
import GA from '@lib/ga';
import { grid_item_progress } from '@lib/shared/state';
import { RenderLazy } from '@lib/shared/render';
import { log_analytic } from '@lib/shared/ajax';

export default function(e, currentTarget) {
  var thumb_clicked = $(currentTarget),
    picture = thumb_clicked.find('picture'),
    container = thumb_clicked.parents('._grid__brick'),
    image_wrap = container.find('._fg-image-wrap'),
    a_id = get_current_user(),
    a_id_viewed = image_wrap.attr('data-attendee-id'),
    grid_section_detail = '_fg-public-section-grid-detail',
    grid_detail_on_demand = $('#gridDetail'),
    viewstats = Globals.PUBLIC_VIEW_STATS,
    myself = a_id == a_id_viewed,
    viewed = viewstats && viewstats.viewed_by,
    view_count = (viewed && viewed[a_id_viewed] && viewed[a_id_viewed].count) || 0,
    no_pics = get_pic_count() < 1,
    scroll = $(window).scrollTop();

  // Prepare the context
  const context = Globals.PUBLIC_IMAGE_DATA.filter(detail => detail.a_id == a_id_viewed).map(filtered_obj => {
    filtered_obj['reveal_view_count'] = view_count && !myself;
    filtered_obj['reply_n'] = filtered_obj.reply_n;
    filtered_obj['view_count'] = view_count;
    filtered_obj['scroll'] = scroll;
    filtered_obj['he'] = filtered_obj.first_name || 'He';
    filtered_obj['plural'] = view_count > 1 ? 's' : '';
    return filtered_obj;
  });

  if (no_pics) {
    return false;
  }

  if (!context.length) {
    return fg_alert('Sorry, no more pics available');
  }

  control_history('#' + a_id_viewed, 'detail');

  log_google_analytics(container, function(element) {
    var post_name = element.attr('data-post-name');
    return GA.dispatch('quickLook', post_name, a_id_viewed);
  });

  grid_item_progress(picture);

  let config = {
    templatePath: 'partials/public-grid-detail',
    context: context[0],
    node: $('#gridDetail')
  };

  RenderLazy(config).then(context => {
    container.addClass('_detail-active');
    fix_lowres_images('._fg-secondary-images-public > ._fg-image-grid');
    public_section_swap(grid_section_detail, 0);
    log_analytic('open_modal', a_id_viewed);
    grid_item_progress(picture);
    // Preload the images
    preload_enlargeable_images(grid_detail_on_demand.find('._fg-secondary-images-public'));
  });
}

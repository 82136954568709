import { get_selected_party, fg_alert } from '@lib/shared/util';
import { searchAndRender, update_elements_after_filter, add_filter_breadcrumb } from '@lib/shared/filtering';
import Globals from '@lib/globals';

export default function(e, ct) {
  var btn = $(ct),
    p_id = get_selected_party(),
    data_coverage = btn.attr('data-coverage');

  if (!p_id) {
    return fg_alert('Select a Party');
  }

  add_filter_breadcrumb(
    data_coverage == 0 ? 'SG Untriggered' : 'SG Triggered',
    Globals.FILTER_SG_TRIGGERED,
    data_coverage
  );

  const args = {
    matchFunction: function(element) {
      var a_id = $(element)
          .find('._fg-modal-on-demand')
          .attr('data-attendee-id'),
        data = ajax_obj.sg_events_coverage,
        found =
          data.find(elem => {
            return elem['a_id'] == a_id;
          }) || false;
      if (data_coverage == 1) {
        return found;
      }
      return !found;
    }
  };

  searchAndRender(args)
    .then(locked_candidates => update_elements_after_filter())
    .catch(err => fg_error(err));
}

import Globals from '@lib/globals';
export default function (e, ct) {
  var btn = $(ct),
      smile = '<i class="far fa-smile text-success animated bounce display-3"></i>';
  if (btn.hasClass('disabled')) return false;
    $('.fa-frown').remove();
    $('._smile-frown').append(smile);
    $('.btn').addClass('disabled');
    $('._will-be-removed').addClass(`${Globals.STYLES.strikethrough} text-muted`);
}

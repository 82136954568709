import Globals from '@lib/globals';
import {ajaxPromise} from '@lib/shared/ajax';
export default function(e, ct) {
  var btn = $(ct),
    a_id = btn.attr('data-attendee-id');

  (async () => {
    try {
      const response = await ajaxPromise({
        action: 'force_logoff_user',
        a_id: a_id
      });
      if (response.status === Globals.STATUS_ERROR) {
        return false;
      }
      btn
        .toggleClass('btn-success disabled', Globals.STATUS_SUCCESS == response.status)
        .toggleClass('btn-danger animated bounce', !Globals.STATUS_SUCCESS == response.status)
        .html(response.msg);
    } catch (e) {
      btn.html(e.message);
    }
  })();
}

import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { fg_alert, are_you_sure } from '@lib/shared/util';

export default function(e, ct) {
  var btn = $(ct),
    callback = function() {
      (async () => {
        /* Before Send init */
        $('._purge-results')
          .children()
          .remove();

        try {
          const response = await ajaxPromise({
            action: 'load_percentiles'
          });

          /* Error status condition */
          if (response.status === Globals.STATUS_ERROR) {
            return fg_alert('Cannot load Percentiles');
          }

          /* Success status condition */
          if (response && response.msg) {
            $('._purge-results').html(`<span class="text-center d-block mt-5">${response.msg}</span>`);
          }
        } catch (e) {
          /* Failure condition */
          fg_alert('Cannot load Percentiles');
        }
      })();
    };

  are_you_sure('reload the percentiles', callback);
}

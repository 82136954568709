import { rest_entry_point } from '@lib/shared/ajax';
import { httpBuildQuery } from '@lib/shared/util';
import capitalize from '@template/helpers/capitalize';

export default function(e, ct) {
  var hovered = $(ct),
    target = hovered.attr('data-target'),
    txtTarget = $(target).find('._interaction'),
    a_id = hovered.attr('data-attendee-id');

  rest_entry_point('ui-misc/?' + httpBuildQuery({ namespace: 'last_correspondence_interaction', a_id: a_id }))
    .then(response => {
      if (response.response && response.response.last_correspondence_interaction) {
        var rec = response.response.last_correspondence_interaction[0] || null;
        if (!rec) {
          txtTarget.html('No recorded events');
        } else {
          var evt = rec.evt,
            date = rec.date;
          txtTarget.html(`"${capitalize(evt)}" on ${date}`);
        }
      }
    })
    .then(() => {
      hovered.removeClass('handle-mouseover');
    })
    .catch(error => {
      fg_error(error);
    });
}

// {{capitalize (get "evt" (get 0 @root.last_interaction))}}" on {{get "date" (get 0 @root.last_interaction)}}

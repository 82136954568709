import { httpBuildQuery, valid_email, fg_alert } from '@lib/shared/util';
import { RenderLazy } from '@lib/shared/render';

export default function(e, ct) {
  var clicked = $(ct),
    p_id = clicked.data('party'),
    checksum = clicked.attr('data-checksum'),
    email = $('#checkin-code').val(),
    email_valid = valid_email( email ),
    attempt = clicked.data('attempt'),
    page_template = 'checkin',
    href = {
      p_id: p_id,
      checksum: checksum,
      email: email || "",
      attempt: attempt
    };

  if ( email && ! email_valid ) {
    return fg_alert( "Invalid e-mail format" );
  }

  clicked.addClass('disabled');

  let config = {
    templatePath: 'checkin/checkin',
    context:
      'ui-checkin/?' + httpBuildQuery(href),
    node: $('#fg-delegate'),
    append: false,
  };
  RenderLazy(config).then(context => {
    fg_console(context);
  });
}

import { ajaxPromise } from '@lib/shared/ajax';
import { get_selected_party, get_party_meta, fg_alert, are_you_sure, clearTimers } from '@lib/shared/util';
import Globals from '@lib/globals';
import { RenderLazy } from '@lib/shared/render';
export default function(e, ct) {
  // Initiate ajax call (get the data)
  var btn = $(ct),
    which_notification = $(btn).attr('data-type'),
    which_notification_class = $(btn).attr('data-class'),
    actual_send = $("._fg-bulk input[name='commit']").val(),
    p_id = +$("#_partySelectDropdownBulkEmail").val() || +get_selected_party(),
    replies = [],
    batch_size = +$("._fg-bulk input[name='batch_size']").val() || 20,
    rating = +$("._fg-bulk input[name='rating']").val(),
    term_slug = $("._fg-bulk input[name='term_slug']").val(),
    subject = $("._fg-bulk input[name='subject']").val(),
    generic_message = $('._fg-generic-body').val(),
    generic_message_sms = $('._fg-generic-body-sms').val(),
    generic_message = generic_message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
    query_only = btn.hasClass('_bulk-query-only'),
    action_buttons = $('button[data-module="handle_launch_bulk_emails"]');

  // Gather replies if applicable
  $('input[name="checkboxFilterByReply"]:checked').each(function(idx, reply) {
    replies.push($(reply).val());
  });

  var submit_ajax = async (batched_batch_size, query_only) => {
    try {
      const response = await ajaxPromise({
        which_notification: which_notification_class,
        actual_send: actual_send,
        batch_size: batched_batch_size,
        rating: rating,
        slug: term_slug,
        subject: subject,
        p_id: p_id,
        replies: replies,
        message: generic_message,
        message_sms: generic_message_sms,
        query_only: query_only || false,
        action: 'bulk_email'
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        throw new Error(response.data.errorText || response.msg);
      }

      /* Success status condition */
      if (response.data) {
        const data = response.data;

        $('._fg-bulk')
          .find('._fg-bulk-stats')
          .append(
            '<pre class="' +
              Globals.STYLES.pre1 +
              '"><span class="' +
              Globals.STYLES.couplet +
              '">' +
              response.msg
                .split(' / ')
                .map(rec => '<span>' + rec.split(': ').join(':</span><span>') + '</span>')
                .join('</span><span class="' + Globals.STYLES.couplet + '">') +
              '</span></pre>'
          );

        if (data.query_results) {
          fg_console(data.query_results, 'data.query_results =>');

          let config = {
            templatePath: 'launch/bulk-query-results',
            context: { query_results: data.query_results },
            node: $('#bulkQueryResults'),
            append: true
          };
          RenderLazy(config);
        }

        if (data.envelope && data.envelope.length) {
          var buffer = `<div class="${Globals.STYLES.fgBulkResults}" style="text-align: left">`;
          buffer += '<h5 class="my-4">Envelope results:</h5>';
          if (data.envelope) {
            $.each(data.envelope, function(idx, itm) {
              buffer += '<div>' + (idx + 1) + '. ' + itm[0] + '</div>';
            });
          }
          buffer += '</div>';
          $('._fg-envelope-results').append(buffer);
        }

        if (data.unsent_counts) {
          action_buttons.each(function(idx, button) {
            var notification_type = $(button).attr('data-type');
            var count = data.unsent_counts[notification_type].count;
            $(button)
              .find('span')
              .html('(' + count + ')');
          });
        }
        $("input[name='commit']").val('');

        // Always
        action_buttons.removeClass('disabled');

        return Promise.resolve(true);
      }

      return Promise.reject(new Error('No data'));
    } catch (e) {
      // fail
      $('._fg-bulk').append(`<span id="batchError" class="${Globals.STYLES.fgAlert_danger} d-inline-block">${e.message}</span>`);

      // Always
      action_buttons.removeClass('disabled');

      return Promise.reject(new Error(e.message, { cause: e }));
    }
  };

  // Validation
  if (
    (p_id == '0' ||
      get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.onice.int ||
      get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.partyover.int) &&
    which_notification != 'generic'
  ) {
    return fg_alert('Must choose an active party if not a generic email');
  }

  if (p_id == '0' && replies.length > 0) {
    return fg_alert('Must choose a party if criteria includes replies');
  }

  // Split the batches
  const miniBatchSize = 5,
    batchSplitter = [],
    numBatches = parseInt(batch_size / miniBatchSize),
    leftover = batch_size % miniBatchSize;
  for (var i = 0; i < numBatches; i++) {
    batchSplitter.push(miniBatchSize);
  }
  if (leftover) {
    batchSplitter.push(leftover);
  }

  // Clear with each run
  $('#batchWorking').empty();
  $('#batchError').remove();

  // Append status to screen
  const addWorking = (str, cls) => {
    cls = cls || Globals.STYLES.fgAlertSm_info;
    var working = $('#batchWorking');
    working.append(`<span class="${cls}">${str}</span>`);
  };

  const iAmSure = async () => {
    var accum = 0;

    $('._fg-bulk-stats,#bulkQueryResults,._fg-envelope-results').empty();
    addWorking('Working ...');
    action_buttons.addClass('disabled');

    if (!query_only) {
      clearTimers();
    }

    try {
      for (var i = 0; i < batchSplitter.length; i++) {
        await submit_ajax(batchSplitter[i], query_only);
        accum += batchSplitter[i];
        addWorking(`Batch: ${i}: Completed ${accum} sends out of ${batch_size}`);
      }
      addWorking('Done', Globals.STYLES.fgAlertSm_success);
    } catch (e) {
      var msg = 'Halting bulk send because of exception: ' + e.message;
      fg_error( e );
      fg_alert( msg );
      addWorking('Done with Error', Globals.STYLES.fgAlertSm_danger);
    }
  };

  if (query_only) {
    iAmSure();
  } else {
    are_you_sure(
      'send these ' + (actual_send ? '<strong>COMMITTED</strong>' : '<strong>TEST</strong>') + ' messages',
      iAmSure
    );
  }
}

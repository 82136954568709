import {
  control_history,
  addImageProcess,
  log_google_analytics,
  get_selected_party,
  get_party_meta,
  fg_alert
} from '@lib/shared/util';
import Globals from '@lib/globals';
import GA from '@lib/ga';
import { log_analytic } from '@lib/shared/ajax';
import { grid_item_progress, toggle_enlarged_image } from '@lib/shared/state';

export default function(e, ct) {
  var img = $(ct).find('img'),
    brick_parent = $(ct).parents('._grid__brick'),
    picture = $(ct).find('picture'),
    image_parent = img.parents('._fg-single-image-public'),
    attachment_id_clicked = img.parents('picture').attr('data-attachment-id'),
    a_id = $('._grid__brick._detail-active').attr('data-attendee-id') || brick_parent.attr('data-attendee-id'),
    gd_on_demand = $('._enlarge-on-demand'),
    awaitBounce = async function(scroll) {
      await scrollToIt(scroll);
    },
    scrollToIt = scroll => {
      return new Promise((resolve, reject) => {
        window.scrollTo(0, scroll);
        resolve('scrolled');
      });
    },
    fg_enlarge = function() {
      control_history('#' + a_id, 'enlarge');

      let enlarge_attributes = null;
      (function() {
        try {
          let attendee_image_data = Globals.PUBLIC_IMAGE_DATA.filter(obj => obj.a_id == a_id);
          let other_image = attendee_image_data[0].picture_data[a_id].images.filter(
            obj => obj.attach_id == attachment_id_clicked
          );
          enlarge_attributes = other_image[0].render.enlarge;
        } catch (e) {}
      })();

      // Empty the picture element for recycling this modal
      const modal_element = $('#_fg-enlarge');
      modal_element.find('picture').empty();

      // Save the new a_id (for tiny_images, etc.
      modal_element.attr('data-attendee-id', a_id);

      const baseUrl = ajax_obj.upload_url + '/',
        picture_element = modal_element.find('picture');

      if (enlarge_attributes) {
        // Populate the sources
        $.each(enlarge_attributes.sources, (index, source_data) => {
          const source = $('<source></source>');
          $.each(source_data, (key, val) => {
            if (key == 'srcset') {
              val = val
                .split(', ')
                .map(pair => {
                  var src_size = pair.split(' ');
                  src_size[0] = baseUrl + src_size[0].trim();
                  return src_size.join(' ');
                })
                .join(', ');
            }
            source.attr(key, val);
          });
          picture_element.append(source);
        });

        picture_element.append('<img/>');

        // Populate the image attributes
        $.each(enlarge_attributes.image_atts, (key, val) =>
          picture_element.find('img').attr(key, key == 'data-full-image' || key == 'src' ? baseUrl + val : val)
        );

        // Finally add any picture attributes
        $.each(enlarge_attributes.picture_atts, (key, val) => picture_element.attr(key, val));
      } else {
        // Try the legacy non-picture elements
        var full_image = img.attr('data-full-image') || img.parents('picture').attr('data-full-image'),
          image_element = $('<img/>');
        if (!full_image) {
          return fg_alert('Unable to enlarge image');
        }
        image_element.attr('src', baseUrl + full_image);
        picture_element.html(image_element);
      }

      var rendered_img = picture_element.find('img');

      // Await the image load then show and adjust the max-width
      addImageProcess(rendered_img.attr('src'))
        .then(image => {
          gd_on_demand.attr('data-scroll', $(window).scrollTop());
          awaitBounce(0);
          return image.naturalWidth;
        })
        .then(naturalWidth => {
          toggle_enlarged_image(true);
          return naturalWidth;
        })
        .then(naturalWidth => {
          let maxWidth = Math.max(0, Math.min(naturalWidth, rendered_img.width()));
          rendered_img.css('max-width', maxWidth);
        })
        .catch(error => {
          fg_error(error);
          fg_alert('Unable to enlarge image');
        })
        .finally(() => {
          image_parent.find('._icon._enlarge').addClass('d-none');
          grid_item_progress(picture);
          $('._fg-back-to-grid').removeClass('invisible');
        });
    };

  // Progress
  grid_item_progress(picture);

  $('._fg-back-to-grid').addClass('invisible');

  var p_id = get_selected_party();
  // Only log analytics for active parties
  if (get_party_meta(p_id, 'is_active') != Globals.PARTY_STATUS.onice.int) {
    log_google_analytics(img, function(element) {
      var full_img = element.attr('data-full-image') || img.parents('picture').attr('data-full-image'),
        a_id_viewed =
          img.parents('._fg-image-grid').attr('data-attendee-id') || img.parents('picture').attr('data-attendee-id');
      log_analytic('enlarge', full_img);
      return GA.dispatch('trackEnlarge', full_img, a_id_viewed);
    });
  }

  fg_enlarge();
}

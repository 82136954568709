import { waiting, done_waiting, set_cookie, admin_logged_in } from '@lib/shared/util';
import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';

export default function (e, currentTarget) {
  var btn = $(currentTarget),
    input = $('._fg-input-change-password'),
    confirm = $('._fg-input-change-password-confirm'),
    password = input.val(),
    a_id = btn.attr('data-attendee-id'),
    feedback_alert = $('[class*="_fg-change-password"] ._feedback-alert'),
    unset_alert = $('[class*="_fg-change-password"] ._unset-alert'),
    show_password_btn = $('._show-password'),
    password_buttons = $('._password-buttons'),
    password_instructions = $('._password-instructions'),
    password_feedback = $('._password-feedback'),
    login_now = $('._login-now'),
    styles = Globals.STYLES;

  if (btn.hasClass('disabled')) {
    return false;
  }

  (async () => {
  	/* Before Send init */
    show_password_btn.addClass('invisible');
    feedback_alert.addClass('d-none').removeClass(`${styles.success} ${styles.danger} animated shakeX fadeInUp`);
    waiting(btn);

  	try {
  		const response = await ajaxPromise({
        password: password,
        a_id: a_id,
        action: 'password_store_new',
  		});

  		/* Error status condition */
  		if (response.status === Globals.STATUS_ERROR) {
        $('.fa-spin').remove();
        feedback_alert
          .html(response.msg)
          .addClass(`${styles.fgAlert_danger} animated shakeX`)
          .removeClass('d-none');
        show_password_btn.removeClass('d-none');
        return false;
  		}

  		/* Success status condition */
      input.add(confirm).val('');
      feedback_alert
        .html(response.msg)
        .removeClass(`${styles.fgAlert_danger} d-none`)
        .addClass(`${styles.fgAlert_success} animated fadeInUp`);
      unset_alert.addClass('d-none');
      btn.addClass('disabled');
      done_waiting(btn, 'Set Password');
      input
        .add(confirm)
        .add(password_instructions)
        .add(password_buttons)
        .add(password_feedback)
        .toggleClass('d-none', confirm);
      login_now.toggleClass('d-none', !confirm);
      if (confirm.length) {
        // Prevent going straight to party-orig
        set_cookie('_fgl_logged_in');
      }

  	} catch (e) {
      /* Failure condition */
  	}
  })();

  // Must return false to prevent LastPass from submitting
  return false;
}



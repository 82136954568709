import { animateCSS } from '@lib/shared/util';
import { adjust_page_for_global_notice } from '@lib/shared/state';
import Globals from '@lib/globals';

export default function(e, currentTarget) {
      var clicked = $(currentTarget),
          notice = $('._global-notice');
      animateCSS( notice, 'fadeOut' )
        .then(message => {
          $('#_selectedParty').removeClass(`_first-class _emergency-alert ${Globals.STYLES.emergencyAlert} ${Globals.STYLES.firstClass}`);
        })
        .then(() => adjust_page_for_global_notice());
}

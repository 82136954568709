import { valid_password } from '@lib/shared/util';

export default function(e, currentTarget, arb_args) {
  let parent = arb_args.parent;

  var input = $(currentTarget),
    value = input.val(),
    feedback_alert = $('[class*="_fg-change-password"] ._feedback-alert'),
    password_feedback = $('._password-feedback'),
    btn_change_password = $('._fg-btn-change-password'),
    btn_show_password = parent.find('._show-password'),
    is_confirm_input = input.hasClass('_fg-input-change-password-confirm'),
    first_password_value = $('._fg-input-change-password').val(),
    passwords_match = () => {
      var both = $('[class*="_fg-input-change-password"]'),
        count = both.length;
      if (count === 1) {
        return true;
      }
      var matches = [];
      $.each(both, (i, password_input) => {
        matches.push($(password_input).val());
      });
      var unique_matches = [...new Set(matches)];
      return unique_matches.length === 1;
    };

  var password_valid = valid_password(first_password_value) === true && first_password_value != '';

  input.siblings('.fa-thumbs-up').remove();

  btn_change_password
    .toggleClass('disabled', !(password_valid && passwords_match()))
    .toggleClass('btn-success', password_valid);

  password_feedback
    .removeClass('invisible')
    .toggleClass('text-danger', !password_valid || !passwords_match())
    .toggleClass('text-success', password_valid && passwords_match())
    .html(() => {
      if (password_valid && passwords_match()) {
        return 'Password OK';
      }
      if (!password_valid) {
        return 'Insufficient Password';
      }
      if (!passwords_match()) {
        return 'Passwords Must Match';
      }
    });
  feedback_alert.addClass('d-none');
  input.toggleClass('invalid', !password_valid);
  btn_show_password.toggleClass('invisible d-none', first_password_value == '');

  if (password_valid) {
    return true;
  }

  if (value == '' && !is_confirm_input) {
    input.attr('type', 'password');
    btn_show_password.html('Show Password');
    return true;
  }
}

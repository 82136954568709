import { control_history } from '@lib/shared/util';
import { toggle_enlarged_image } from '@lib/shared/state';

export default function( e, ct ) {
  const scrollToIt = () => {
      return new Promise( ( resolve, reject ) => {
        window.scrollTo( 0, scroll );
        resolve( 'scrolled' );
      } );
    },
    scroll = $( '._enlarge-on-demand' ).attr( 'data-scroll' );

  toggle_enlarged_image( false );

  ( async () => {
    await scrollToIt();
    let a_id = $( '._grid__brick._detail-active' ).attr( 'data-attendee-id' ) || $('#_fg-enlarge').attr('data-attendee-id');
    control_history( '#' + a_id, 'detail' );
  } )();

}

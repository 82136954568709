import Globals from '@lib/globals';
import {
  admin_logged_in,
  get_pic_count,
  fg_alert,
  animateCSS,
  are_you_sure
} from '@lib/shared/util';
import { ajaxPromise, load_grid, load_profile_images } from '@lib/shared/ajax';
import { user_endpoint_init } from '@lib/shared/state';

export default function(e, currentTarget) {
  const remove_btn = $(currentTarget),
    pic_container = remove_btn.parents('._image-management-data'),
    a_id = pic_container.attr('data-attendee-id'),
    image_id = pic_container.attr('data-other-images-id'),
    image_status = pic_container.data('image-status'),
    is_primary = pic_container.attr('data-primary-thumb-id') == pic_container.attr('data-thumb-id'),
    on_ice = $('._on-ice-wrapper._on-ice').length > 0,
    undo = false,
    remove_the_image = async function(modal) {
      /* Before Send init */
      try {
        const response = await ajaxPromise({
          image_id: image_id,
          a_id: a_id,
          action: 'user_remove_image'
        });

        /* Error status condition */
        if (response.status === Globals.STATUS_ERROR) {
          fg_error(response.data);
          return false;
        }

        /* Success status condition */
        await load_profile_images();

        if (!on_ice) {
          user_endpoint_init();
          load_grid();
        }
        if ( modal ) modal.modal('hide');
      } catch (e) {
        if ( e.message != "no_pics" ) {
          /* Failure condition */
        }
      }
    };

  var active_pic_count = get_pic_count(),
      one_active_pic_left = active_pic_count === 1 && image_status === Globals.IMAGE_STATUS.approved.int;

  if (!admin_logged_in() && one_active_pic_left && !undo) {
    return fg_alert(
      'You will have no more (visible) pics remaining if you remove this one. Continuing will prevent you from seeing the other guys',
      function(modal) {
        modal.find('button').on('click', function(e2) {
          var button = $(e2.target);
          if (!button.is('.click-no-images') || button.is('._fg-undo')) {
            return;
          }
          remove_the_image(modal);
        });
      },
      {
        waitlist: {
          label: 'Continue Without Images',
          classes: 'btn btn-danger click-no-images'
        }
      }
    );
  }

  if (is_primary && !admin_logged_in() & !undo) {
    return fg_alert(
      'You cannot remove your primary image: Make a different image primary. If this is your only image, upload a new one first.'
    );
  }

  are_you_sure('remove this image?', remove_the_image);
}

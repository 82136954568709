import {
  add_filter_breadcrumb,
  create_search_construct,
  searchAndRender,
  searchingForFromConstruct,
  searchbox_filter,
  update_elements_after_filter
} from '@lib/shared/filtering';
import { get_parties_attended, get_selected_party, bounceBack } from '@lib/shared/util';
import Globals from '@lib/globals';

export default function(e, ct) {
  Globals.LOCKED_FILTERS = [];

  $(ct).remove();

  var all = $('#filterBreadcrumb > ._fg-breadcrumb'),
    searchConstruct = null;

  $.each(all, (idx, breadcrumb) => {
    var key = $(breadcrumb).attr('data-filter-key'),
      value = $(breadcrumb).attr('data-filter-value');
    add_filter_breadcrumb(
      $(breadcrumb)
        .find('.label')
        .html(),
      key,
      value
    );
    if (key == Globals.FILTER_SEARCH) {
      searchConstruct = create_search_construct(value);
    }
  });

  Globals.LOCKED_CANDIDATES = Globals.ORIGINAL_GRID;

  $('#filterBreadcrumb').toggleClass('d-none', all.length < 1);

  if (!all.length) {
    $('._fg-reset-filters')
      .attr('style', '')
      .trigger('click', { manual: 'triggered' });
    return;
  }

  const args = {
    reset: all.length < 1,
    matchFunction: function(element, searchingForFromConstruct) {
      var matches = false,
        matchCount = 0;

      $.each(all, (idx, breadcrumb) => {
        var key = $(breadcrumb).attr('data-filter-key'),
          value = $(breadcrumb).attr('data-filter-value');

        switch (key) {
          case Globals.FILTER_POSITION:
            matches =
              $(element)
                .find('._fag-position')
                .attr('data-name') == value;
            break;
          case Globals.FILTER_TAG:
            let terms = $(element)
              .find('._fg-thumb')
              .attr('data-terms');
            matches = terms && terms.indexOf(value) > -1;
            break;
          case Globals.FILTER_PARTY:
            var a_id = $(element)
                .find('._fg-modal-on-demand')
                .attr('data-attendee-id'),
              party_meta = get_parties_attended(a_id, value);
            matches = party_meta || false;
            break;
          case Globals.FILTER_REPLY:
            var a_id = $(element)
                .find('._fg-modal-on-demand')
                .attr('data-attendee-id'),
              party_meta = get_parties_attended(a_id, get_selected_party());
            matches = party_meta && party_meta['reply_n'] == value;
            break;
          case Globals.FILTER_SHOWED:
            var a_id = $(element)
                .find('._fg-modal-on-demand')
                .attr('data-attendee-id'),
              party_meta = get_parties_attended(a_id, get_selected_party());
            matches = party_meta && party_meta['showed'] == value;
            break;
          case Globals.FILTER_SG_TRIGGERED:
            var a_id = $(element)
                .find('._fg-modal-on-demand')
                .attr('data-attendee-id'),
              data = ajax_obj.sg_events_coverage,
              found =
                data.find(elem => {
                  return elem['a_id'] == a_id;
                }) || false;
            if (value == 1) {
              matches = found;
            } else {
              matches = !found;
            }
            break;
          case Globals.FILTER_SEARCH:
            const searchingFor = value,
              reset = !searchingFor || searchingFor == '';

            matches = searchbox_filter(element, searchingForFromConstruct);
            break;
          default:
        }
        if (matches) matchCount++;
      });
      return matchCount === all.length;
    },
    searchConstruct: searchConstruct
  };

  let filterTop = $('#filterBreadcrumb').length ? $('#filterBreadcrumb') : $('._fg-grid-wrapper');

  searchAndRender(args)
    .then(locked_candidates => update_elements_after_filter())
    .then(() => {
      let scrollLocation = all.length ? filterTop.offset().top - 20 : 0;
      bounceBack(scrollLocation);
    })
    .catch(err => fg_error(err));
}

import { ajaxPromise, load_grid } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { progressIcon, admin_logged_in } from '@lib/shared/util';
import { update_position_counts } from '@lib/shared/filtering';

export default function (e, currentTarget, arb_args) {
  var checked_box = $(currentTarget),
    parent = arb_args.parent,
    meta_key = checked_box.attr('name'),
    meta_value = checked_box.attr('data-value'),
    option_value = checked_box.attr('value'),
    post_id = checked_box
      .parents('._fg-edit-post')
      .attr('data-post-id'),
    action = checked_box
      .parents('.form-group')
      .attr('data-action'),
    the_label = checked_box
      .parent()
      .next('label');

  // Prevent double submissions
  if (checked_box.hasClass('selected')) {
    e.preventDefault();
    return false;
  }

  if ($('#fg-delegate').is('[class*="ajax-"]')) {
    return false;
  }

  // Clear other checked boxes, add new
  checked_box
    .parents('.form-group')
    .find('input')
    .removeClass('selected')
    .removeAttr('checked')
    .end()
    .end()
    .addClass('selected')
    .attr('checked', 'checked');

  // Overriding default checkbox behavior to only allow one value
  var group = checked_box
    .parents('._wpt-form-set-checkboxes-wpcf-position')
    .find('input.checkbox');

  (async () => {
  	/* Before Send init */
    $(the_label).append(progressIcon(Globals.STYLES.progress));

  	try {
  		const response = await ajaxPromise({
        post_id: post_id,
        meta_key: meta_key,
        meta_value: meta_value,
        option_value: option_value,
        action: action
  		});

  		/* Error status condition */
  		if (response.status === Globals.STATUS_ERROR) {
        $(the_label).append(progressIcon('error'));
        return false;
  		}

  		/* Success status condition */
      $(the_label).append(progressIcon('success'));
      parent.find('input').get().forEach(item => {
        item.checked = false;
      });
      checked_box[0].checked = true;
      if ( ! admin_logged_in() && meta_key.indexOf('wpcf-position') !== -1 ) {
        await load_grid();
        update_position_counts();
      }

  	} catch (e) {
      if ( e.message != "no_pics" ) {
        /* Failure condition */
      }
  	}
  })();

}

import Globals from '@lib/globals';
import { are_you_sure, fg_alert } from '@lib/shared/util';
import { ajaxPromise, reload_parties_attended } from '@lib/shared/ajax';
import { RenderLazy } from '@lib/shared/render';

export default function(e, ct) {
  var btn = $(ct),
    reminder = $('._fg-welcome-reminder').is(':checked'),
    email = $('input.wpcf-email').val(),
    first_name = $('input.wpcf-first-name-private').val() || 'man',
    age = $('input.wpcf-age').val() || '',
    post_status =
      $('select.post-status')
        .children('option:selected')
        .val() || '',
    contact_type =
      $('select.wpcf-contact-type')
        .children('option:selected')
        .val() || '',
    contact_value = $('input.wpcf-contact-value').val() || '',
    location = $('input.wpcf-location').val() || '',
    position = $('input.wpcf-position:checked').attr('data-value') || 'unspecified',
    note = $('input.wpcf-attendee-note').val() || '',
    message = $('textarea._fg-body').val() || '',
    re = /__first_name__/g;
  message = message.replace(re, first_name);
  message = message.replace(/(?:\r\n|\r|\n)/g, '<br>');

  var callback = function() {
    (async () => {
      try {
        const load_form = async () => {
          let config = {
            templatePath: 'launch/launch-panel-welcome-email',
            context: {},
            node: $('#_launch-welcome-email'),
          };
          const context = await RenderLazy( config );
          var cleaned = $('._fg-body')
            .get(0)
            .getInnerHTML()
            .replace(/[ ]{2,}/g, '');
          $('._fg-body').html(cleaned);
        };

        const response = await ajaxPromise({
          action: 'send_welcome_email',
          email: email,
          reminder: reminder,
          wpcf_first_name_private: first_name,
          wpcf_age: age,
          post_status: post_status,
          wpcf_position: position,
          wpcf_attendee_note: note,
          wpcf_contact_type: contact_type,
          wpcf_contact_value: contact_value,
          wpcf_location: location,
          message: message
        });

        /* Error status condition */
        if (response.status === Globals.STATUS_ERROR) {
          return fg_alert('Problem sending email');
        }

        /* Success status condition */
        $(btn).html('Successfully sent');

        reload_parties_attended();

        setTimeout(function() {
          load_form();
        }, 4000);
      } catch (e) {
        /* Failure condition */
        fg_alert('Problem sending email');
      }
    })();
  };

  are_you_sure('send this message?', callback);
}

import { scrollToggleFunc } from '@lib/shared/scrollToTop';
import { animateCSS, httpBuildQuery, public_section_swap } from '@lib/shared/util';
import { delay_keystroke } from '@lib/shared/filtering';
import { isAjaxLocked, rest_entry_point, check_version_mismatch, handle_rest_rejections } from '@lib/shared/ajax';
import loadModule from '@lib/shared/loadModule';
import Navigator from '@lib/navigator';
import Globals from '@lib/globals';

export default function listeners() {
  (function() {
    // Global window listeners
    $(window)
      .on('keydown', function(event) {
        if (event.keyCode == 13 && $(event.target).is('input')) {
          event.preventDefault();
          return false;
        }
      })
      /* Prevent dragging to window when using dropzone */
      .on('dragover', function(e) {
        e = e || event;
        e.preventDefault();
      })
      .on('drop', function(e) {
        e = e || event;
        e.preventDefault();
      })
      .on('scroll', scrollToggleFunc);

    // Master handling for all listeners
    const types = {
      click: {
        selector: '.handle-click',
        callback: (e, arb_args) => {
          e.preventDefault();
          e.stopPropagation();
          if ($(e.currentTarget).is('.touch-pulse')) {
            return animateCSS($(e.currentTarget), 'touchPulse').then(() => {
              prepareLazy(e, arb_args);
            });
          }
          return Promise.resolve('Regular click').then(() => {
            prepareLazy(e, arb_args);
          });
        }
      },
      dblclick: { selector: '.handle-double-click' },
      focusout: { selector: '.handle-focus-out' },
      mouseover: { selector: '.handle-mouseover' },
      change: { selector: '.handle-change' },
      keyup: { selector: '.handle-keyup' },
      keyup: {
        selector: '.delay-keystroke',
        callback: function(e, arb_args) {
          let delay = $(e.currentTarget).attr('data-delay');
          return delay_keystroke(e, delay)
            .then(() => {
              e.preventDefault();
              e.stopPropagation();
              prepareLazy(e, arb_args);
            }).catch(e => fg_console(e));
        }
      },
      keydown: {
        selector: '.handle-input',
        callback: (e, arb_args) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            const target = $(e.currentTarget).attr('data-target');
            $(target).trigger('click', arb_args);
            return Promise.resolve('done');
          }
        }
      },
      input: { selector: '.handle-input' }
    };

    for (const [event_type, data] of Object.entries(types)) {
      // arbitrary_args should come in as a plain object if triggered, with attribute "manual"
      $('#fg-delegate').on(event_type, data.selector, function(e, arbitrary_args) {
        fg_console(
          'triggered ' +
            event_type +
            ' on ' +
            data.selector +
            ' for: ' +
            e.currentTarget.className +
            ' on ' +
            e.currentTarget.localName
        );
        if (event_type != e.type) {
          return false;
        }
        if (!e.currentTarget.matches(data.selector)) {
          return false;
        }
        if (!$(e.currentTarget).hasClass('_ajax-override') && isAjaxLocked()) {
          return false;
        }
        if (data.callback) {
          (async () => {
            await data.callback(e, arbitrary_args);
          })();
        } else {
          e.preventDefault();
          e.stopPropagation();
          prepareLazy(e, arbitrary_args);
        }
      });
    }

    // Check version with first interaction
    $('#fg-delegate').one('keyup', '.check-version', e => {
      rest_entry_point('ui-misc-public/?' + httpBuildQuery({ namespace: 'check_version' }))
        .then(response => {
          fg_console('Version Check', response);
          const local_version = $('body').attr('data-version'),
            actual_version = response.response && response.response.check_version;
          if (actual_version !== local_version) {
            throw new Error('Version Mismatch', {
              cause: rest_obj.response_codes.REST_VERSION_MISMATCH
            });
          }
        })
        .catch(e => {
          handle_rest_rejections({ code: e.cause, message: e.message }, ajax_obj.home_url + '/getin/');
        });
    });

    // Handle popstate
    const s = Globals.STYLES;
    window.onpopstate = function(event) {
      if (event.state === null) {
        event.preventDefault();
        return false;
      }
      fg_console('POPPED', event.state);
      try {
        switch (event.state.type) {
          case 'enlarge':
            $('._fg-back-to-grid').trigger('click');
          case 'detail':
            if ($('#_fg-enlarge._enlarge-active').length) {
              $('._fg-close').trigger('click');
            } else {
              $('._fg-back-to-grid').trigger('click');
            }
            break;
          case 'menu':
            let last_scroll = $('._fg-back-to-grid').attr('data-scroll') || 0;
            public_section_swap(event.state.orig_url, last_scroll);
            $('._fg-public-menu a').removeClass(`active ${s.active} fg-btn-selected ${s.fgButtonSelected}`);
            $("._fg-public-menu a[data-toggle='" + event.state.orig_url + "']").addClass(
              `active ${s.active} fg-btn-selected ${s.fgButtonSelected}`
            );
            break;
          case 'page':
            let nav = new Navigator(event.state.orig_url, event.state.template);
            nav.go();
          default:
        }
      } catch (e) {
        fg_error(e);
      }
    };
  })();
}

function prepareLazy(e, arbitrary_args) {
  var targetedElement = $(e.currentTarget);
  if (targetedElement.is(':disabled') || targetedElement.hasClass('disabled')) return false;
  const data_module = targetedElement.attr('data-module');
  if (!data_module) {
    throw new Error('Missing data-module on click handler. Consider using handle_noop if not needed');
  }
  let lazy_module = data_module + '.js';
  let args = {};
  args.parent = $(targetedElement.attr('data-parent'));
  if (arbitrary_args && (typeof arbitrary_args === 'function' || typeof arbitrary_args === 'object')) {
    args = { ...args, ...arbitrary_args };
  }
  lazyHandle(e, lazy_module, targetedElement, args);
}

function lazyHandle(e, moduleName, currentTarget, arbitrary_args, parent) {
  const passthrough_args = [e, currentTarget, arbitrary_args, parent];
  loadModule('lib', `handlers/${moduleName}`)
    .then(module => {
      const lazy = module.default;
      if (typeof lazy === 'function') {
        lazy(...passthrough_args);
      } else if (typeof lazy === 'object') {
        lazy.init(...passthrough_args);
      } else {
        throw 'Module: ' + moduleName + ' must be a function or object that references one';
      }
      return { lazy, passthrough_args };
    })
    .then(payload => {
      let { lazy, passthrough_args } = payload;
      if (lazy.finalize) {
        lazy.finalize(...passthrough_args);
      }
      return { lazy, passthrough_args };
    });
}

import { RenderLazy } from '@lib/shared/render';
import { httpBuildQuery, bounceBack } from '@lib/shared/util';
import Globals from '@lib/globals';

export default function(e, ct) {
  var btn = $(ct),
    a_id = btn.attr('data-attendee-id'),
    results_node = $('#_score-breakdown-' + a_id);

  (async t => {
    let config = {
      templatePath: 'partials/score-breakdown',
      context: [
        'attendee-score-breakdown/?' +
          httpBuildQuery({
            a_id: a_id
          })
      ],
      node: t
    };
    await RenderLazy(config);
    bounceBack(t.offset().top);
  })(results_node);
}

import Globals from '@lib/globals';
import { ajaxPromise } from '@lib/shared/ajax';
import { fg_alert } from '@lib/shared/util';

export default function(e, ct) {
  var button = $(ct),
    extension = button.attr('data-extension'),
    message_id = button.attr('data-message-id');

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'load_message_contents',
        extension: extension,
        sg_message_id: message_id
      });

      if (response.data) {
        /* Success status condition */
        var tab = window.open('about:blank', '_blank');
        tab.document.write(response.data.contents); // where 'html' is a variable containing your HTML
        tab.document.close(); // to finish loading the page
      } else {
        fg_alert('No data returned for message contents');
      }
    } catch (e) {
      /* Failure condition */
    }
  })();
}

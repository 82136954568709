import { ajaxPromise } from '@lib/shared/ajax';
import Globals from '@lib/globals';
import { RenderLazy } from '@lib/shared/render';
import { reload_parties_attended } from '@lib/shared/ajax';
import { httpBuildQuery } from '@lib/shared/util';
import { listen_for_card_expansions } from '@lib/shared/state';

export default function(e, ct) {
  var clicked = $(ct),
    dropdown = $('#_partySelectDropdownAddToParty'),
    p_id = dropdown.find(':selected').val(),
    a_id = clicked.attr('data-attendee-id'),
    batch_id_arr = [a_id],
    unlink = clicked.is('#removeLink');

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'batch_action_init_rsvp',
        p_id: p_id,
        batch_id_arr: batch_id_arr,
        delete: unlink
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        clicked.html(response.msg);
        return false;
      }

      /* Success status condition */
      clicked.html(response.msg);

      await reload_parties_attended();

      let config = {
        templatePath: 'attendee-master/atm-parties-attended',
        context: [
          'ui-crud-user-profile/?' +
            httpBuildQuery({
              namespace: 'user_profile',
              a_id: a_id
            }),
          {
            response: { parties_attended: ajax_obj.parties_attended.filter(rec => rec.a_id == a_id) }
          }
        ],
        node: $('#atmPartiesAttended')
      };

      await RenderLazy(config);

      listen_for_card_expansions();
    } catch (e) {
      /* Failure condition */
    }
  })();
}

import Globals from '@lib/globals';
import { searchAndRender, update_elements_after_filter, locked_candidates, original_grid } from '@lib/shared/filtering';
import { log_analytic } from '@lib/shared/ajax';
import { bounceBack } from '@lib/shared/util';

export default function(e, currentTarget, arb_args) {
  var btn = $(currentTarget);
  $('._fg-filter .collapse').collapse('hide');
  searchAndRender({ reset: true })
    .then(locked_candidates => update_elements_after_filter())
    .then(bounceBack(0))
    .then(() => {
      setTimeout(function() {
        $('._fg-filter-wrapper ')
          .find('.btn')
          .removeClass('fg-btn-selected');
        log_analytic('filter-reset', 'Reset');
      }, 1000);
      $('#filterBreadcrumb')
        .addClass("d-none")
        .children()
        .remove();
      if (arb_args && arb_args.manual) {
        $('#_fg-search-box')
          .add('._atm-buttons .btn,._atm-buttons input,._fg-filter .btn')
          .removeClass('disabled');
      }
      Globals.LOCKED_FILTERS = [];
    })
    .then(() => {
      let og = $('#fg-main-grid ._grid__brick').get();
      original_grid( og );
      locked_candidates( og );
    })
    .catch(err => fg_error(err));
}

import Globals from '@lib/globals';
import { ajaxPromise } from '@lib/shared/ajax';

export default function(e, ct) {
  var clicked = $(ct),
    a_id = clicked.attr('data-attendee-id');

  (async () => {
    /* Before Send init */

    try {
      const response = await ajaxPromise({
        action: 'user_unsubscribe',
        a_id: a_id
      });

      /* Error status condition */
      if (response.status === Globals.STATUS_ERROR) {
        $('#_unsub-failure').removeClass('d-none');
        return false;
      }

      /* Success status condition */
      $('#_unsub-success').removeClass('d-none');
      $('#_unsub_ui').addClass('d-none');
    } catch (e) {
      /* Failure condition */
    }
  })();
}

import { get_selected_party, httpBuildQuery } from '@lib/shared/util';
import { RenderLazy } from '@lib/shared/render';

export default function(e, ct) {
  var select = $(ct),
    p_id = get_selected_party();

  let config = {
    templatePath: 'launch/bulk-action-buttons',
    context:
      'ui-misc/?' +
      httpBuildQuery({
        namespace: 'unsent_notification_counts',
        p_id: p_id
      }),
    node: $('#bulkActionButtons')
  };

  RenderLazy(config);
}
